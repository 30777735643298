import './style.css';

const Footer = () => {
  return (
    <footer>
      <div className="container sharewidth">
        <div style={{ paddingRight: 15 }}>
          &copy; 2023 Alex Mallory. All rights reserved. Photo credits can be found on the Portfolio page.
        </div>
        <div style={{ minWidth: 75 }}>
          <a href="https://www.facebook.com/alex.mallory" target="_blank" rel="noreferrer">
            <img
              src="/images/social/round/facebook.png"
              className="social"
              alt="Facebook"
            />
          </a>
          &nbsp;
          {/* <a href="https://www.twitter.com/alexmallory" target="_blank" rel="noreferrer">
            <img
              src="/images/social/round/twitter.png"
              className="social"
              alt="Twitter"
            />
          </a> */}
        </div>
      </div>
    </footer>
  );
};
export default Footer;
