const Reviews = () => {
  return (
    <div>
    <div>
      <h2>
        Critical Reviews for <em>The Cave</em>
      </h2>
      <div className="post">
        <p>
          <a
            href="https://www.chicagotribune.com/2025/02/12/review-the-cave-at-a-red-orchid-theatre-is-about-an-ohio-family-at-the-start-of-the-gulf-war/"
            target="_blank" rel="noreferrer"
          >
            Chicago Tribune
          </a> (Chris Jones)
          <br />
"The family joshing and loving is great fun here; both Liss and Montana are
terrific, live-wire young performers, thanks surely in part to Alex Mallory’s
direction"
          <br />“A most intriguing (and, trust me, <em>moving</em>) work-in-progress from one of Chicago’s most gifted artists, put on by a generous creative ensemble that did everything in its considerable power to bring her vision to life. ”
        </p>
        <p>
          <a
            href="https://chicagoreader.com/performing-arts/theater/theater-review/the-cave-red-orchid-theatre-review/"
            target="_blank" rel="noreferrer"
          >
            Chicago Reader
          </a> (Kerry Reid)
          <br />
          “Director Alex Mallory’s cast for A Red Orchid Theatre works as a true ensemble, listening and reacting to each other so well that it is easy to believe the family dynamics onstage.”
        </p>
        <p>
          <a
            href="https://www.chicagotheatrereview.com/2025/02/an-autobiographical-drama/"
            target="_blank" rel="noreferrer"
          >
            Around the Town Chicago
          </a> (Julia W. Rath)
          <br />
          “There is a freshness of spirit among the cast, and a performance that is billed as 2-1/2 hours with a 15-minute intermission goes by in a flash.”
        </p>
        <p>
          <a
            href="https://www.chicagotheatrereview.com/2025/02/an-autobiographical-drama/"
            target="_blank" rel="noreferrer"
          >
            Chicago Theatre Review
          </a> (Colin Douglas)
          <br />
          “Directed with style and passion by Alex Mallory.”
        </p>
        <p>
          <a
            href="https://www.hollywoodchicago.com/news/38915/theater-review-past-present-in-a-red-orchid-s-the-cave"
            target="_blank" rel="noreferrer"
          >
            Hollywood Chicago
          </a> (Patrick McDonald)
          <br />&#9734;  &#9734;  &#9734;  &#9734;  &#9734;
          <br />  “When the Persian Gulf War begins to escalate, represented by a searing use of stagecraft and video ... the threads of sanity begin to unravel, with the family and community going into breakdown mode.”
        </p>
      </div>
    </div>
    <br />
    <hr className="post" />
      <div>
        <h2>
          Critical Reviews for <em>In The Wake</em>
        </h2>
        <div className="post">
          <p>
            <a
              href="http://www.chicagotheatrereview.com/2017/07/overcoming-her-blind-spot/"
              target="_blank" rel="noreferrer"
            >
              Chicago Theatre Review
            </a>
            <br />
            “This terrific directing debut with The Comrades comes from Alex
            Mallory. She understands all the nuances and quirks of these
            interesting characters and has guided her seven-member cast to some
            sublime performances.”
          </p>
          <p>
            <a
              href="http://www.picturethispost.com/comrades-present-wake-review-stellar-cast-on-the-mark/"
              target="_blank" rel="noreferrer"
            >
              Picture This Post
            </a>
            <br />
            “The Comrades, an ensemble-based company founded less than two years
            ago, gives IN THE WAKE the kind of skilled production that maintains
            Chicago’s reputation as a city of small stages. In the hands of
            director Alex Mallory, it’s both nuanced and straightforward.”
          </p>
          <p>
            <a
              href="http://www.windycitymediagroup.com/lgbt/THEATER-REVIEW-In-the-Wake-/60037.html"
              target="_blank" rel="noreferrer"
            >
              Windy City Times
            </a>
            <br />
            “In the Wake is filled with very astute writing, delivered in a
            consistently believable manner by the cast under Alex Mallory's
            smart direction and generally-crisp pacing.”
          </p>
          <p>
            <a
              href="http://www.lasplash.com/publish/Entertainment/cat_index_chicago_entertainment/in-the-wake-review_printer.php"
              target="_blank" rel="noreferrer"
            >
              Splash Magazines
            </a>
            <br />
            “Tautly directed by Alex Mallory, “In the Wake” features 7 fine
            actors- 6 of whom are women- portraying closely linked lives during
            a slice of time beginning with the first election of GWB, and
            encompassing “9/11” and that President’s second term."
          </p>
        </div>
      </div>
      <br />
      <hr className="post" />
      <div>
        <h2>
          Critical Reviews for <em>Dijla Wal Furat</em>
        </h2>
        <div className="post">
          <p>
            <a
              href="http://www.talkinbroadway.com/ob/2_10_15.html"
              target="_blank" rel="noreferrer"
            >
              Talkin' Broadway
            </a>
            <br />
            "The performances under Alex Mallory’s direction are first-rate all
            around."
          </p>
          <p>
            <a
              href="http://newyorktheatrereview.blogspot.com/2015/02/kyle-stockburger-on-maurice-decauls.html"
              target="_blank" rel="noreferrer"
            >
              New York Theatre Review
            </a>{' '}
            <br />
            “It’s a dreamlike experience that leaves the audience contemplating
            the true extent and fallout of a seemingly unending conflict.”
          </p>
          <p>
            <a
              href="http://www.charged.fm/blog/post/6493/dijla-wal-furat-between-the-tigris-and-the-euphrates"
              target="_blank" rel="noreferrer"
            >
              Charged.fm
            </a>
            <br />
            "The languages represented on stage include not only English and
            Arabic, but French and Spanish as well, yet in this cacophony of
            voices it is still always clear what is going on. The heart of the
            story always comes through."
          </p>
          <p>
            <a href="http://artsinternational.blogspot.com/2015/02/theater-review-by-bina-sharif-dijla-wal.html" target="_blank" rel="noreferrer">
              Arts International
            </a>
            <br />
            "The production is brilliantly directed by Alex Mallory."
          </p>
        </div>
      </div>
      <br />
      <hr className="post" />
      <div>
        <h2>
          Further Writing about <em>Dijla Wal Furat</em>
        </h2>
        <div className="post">
          <p>
            <a
              href="http://acolytesofwar.com/2015/03/01/how-we-were-maurice-decauls-stage-vision-of-2003/"
              target="_blank" rel="noreferrer"
            >
              How We Were: Maurice Decaul’s Stage Vision of Iraq, 2003
            </a>
            <br />
            A blog post on "Time Now" by former Army officer Peter Molin
            <br />
            March 1, 2015
          </p>
        </div>
      </div>
      <br />
      <hr className="post" />
      <div>
        <h2>
          Critical Reviews for <em>Goliath</em>
        </h2>
        <div className="post">
          <p>
            <a
              href="http://www.nytheatre.com/Review/kimberly-sparkle-stewart-2011-6-4-goliath"
              target="_blank" rel="noreferrer"
            >
              nytheatre.com
            </a>
            <br />
            "On Saturday night, I witnessed one of the rare gifts that theatre
            bestows upon us from time to time—a powerful and passionately
            produced piece, raising important questions about the world we live
            in today. That piece is GOLIATH, produced by Poetic Theater
            Productions."
          </p>
          <p />
          “Alex Mallory’s direction is deft. Each actor is a character in
          David’s life, but also a member of a sort of Greek chorus commenting
          on the world that surrounds him and his choices. Her staging allows
          the actors to slip seamlessly among these roles and it unobtrusively
          supports and defines the poetry that it is rooted in. She creates
          evocative movement that often suggests the realism of war
          photojournalism in a couple of moments, and then the youthful tableau
          of a house party with an ease and grace that orients the audience to
          the map of this quick moving piece. There is a polish to the choices
          and a meaning to her arrangement of the actors upon the stage that
          slowly reveals itself as the action proceeds."
          <p />
          <p>
            <a
              href="http://showshowdown.blogspot.com/2011/06/goliath-choreopoem.html"
              target="_blank" rel="noreferrer"
            >
              Show Showdown
            </a>
            <br />
            “As beautifully rendered by Rivera, Mallory, and an excellent cast,
            there is nothing here that is anything less than fresh, honestly
            emotional, heartbreaking, and true. Rivera and Mallory use scenes
            and monologues, choral testimony and hard-hitting visuals to find
            new ways to say something simple but profound: war is a perversion
            of humanity.”
          </p>
          <p>
            <a
              href="http://www.theasy.com/Reviews/2012/G/goliath.php"
              target="_blank" rel="noreferrer"
            >
              Theatre is Easy
            </a>
            <br />
            "An inventive and important work of poetic theatre that's smartly
            contrived and incredibly affecting."
          </p>
          "This talented cast finds the emotional strife in their stories
          without needing to comment on their circumstances. This is largely due
          to Alex Mallory's smart direction."
          <p />
          "Mallory's visceral staging is almost more choreography than
          direction, and the lines are often spoken by more than one cast member
          and delivered in a style that lets the ensemble breathe as one being
          despite seven individual perspectives."
          <p />
          <p>
            <a
              href="http://www.stagebuzz.com/2012/01/poetic-theaters-goliath-shows-soul.html"
              target="_blank" rel="noreferrer"
            >
              Stage Buzz
            </a>
            <br />
            "The use of language and chorus is amazing and speaks volumes both
            about Rivera's writing and director Alex Mallory's excellent feel
            for the material. The use of poetic language and chorus and
            repetition in the dialogue brings a sense of heightened drama to the
            play. It's a very nice touch and one that Mallory exploits to great
            effect."
          </p>
          <p>
            <a
              href="http://urbancrazes.com/featured/theater-review-goliath/"
              target="_blank" rel="noreferrer"
            >
              Urban Crazes
            </a>
            <br />
            "I highly recommend the American Veteran Affairs Department to
            consider producing GOLIATH within rehabilitation centers throughout
            the nation."
          </p>
        </div>
      </div>
      <br />
      <hr className="post" />
      <div>
        <h2>
          Further Writing about <em>Goliath</em>
        </h2>
        <div className="post">
          <p>
            <a
              href="http://www.warwickadvertiser.com/opinion/letters-to-the-editor/honor-the-sacrifice-EUWA20121106121109972"
              target="_blank" rel="noreferrer"
            >
              'Honor the Sacrifice'
            </a>
            <br />
            A letter to the editor by Vietnam veteran Everett Cox In the Warwick
            Advertiser
            <br />
            November 6, 2012
          </p>
          <p>
            <a href="http://wlajournal.com/blog/?p=713" target="_blank" rel="noreferrer">
              Questions
            </a>
            <br />
            A War, Literature &amp; the Arts Journal blog post by retired Air
            Force Pilot and professor of War &amp; Literature at the Air Force
            Academy, James A. Moad II
            <br />
            September 15, 2012
          </p>
          <p>
            <a
              href="https://theaterconnects2.wordpress.com/2011/06/06/holding-a-mirror-up-to-nature/"
              target="_blank" rel="noreferrer"
            >
              Holding a Mirror up to Nature
            </a>
            <br />
            A blog post by Megan Janel Zimmer, Theater Connects
            <br />
            June 6, 2011
          </p>
        </div>
      </div>
    </div>
  );
};
export default Reviews;
