import './gallery.css';

const Gallery = () => {
  return (
    <>
      <h2>Directing Portfolio</h2>
      <div className="gallery-links">
        <a rel="contents" href="#cave">
          <img src="/images/cave/sisters.jpg" width="200px" alt="The Cave" /> <br /> The Cave{' '}
        </a>
        <a rel="contents" href="#lysistrata">
          <img src="/images/lysistrata/lysistrata_thumb.jpg" width="200px" alt="Lysistrata" /> <br /> Lysistrata{' '}
        </a>
        <a rel="contents" href="#rachel">
          <img src="/images/rachel/emiley4.jpg" width="200px" alt="My Name is Rachel Corrie" /> <br /> My Name is Rachel
          Corrie
        </a>
        <a rel="contents" href="#witch">
          <img src="/images/witch/witch_thumbs/gavin.jpg" width="200px" alt="Witch" />
          <br />
          Witch
        </a>
        <a rel="contents" href="#elephant">
          <img src="/images/elephant/_APR4003_crop.jpg" width="200px" alt="The Elephant Speaks Jazz" />
          <br />
          The Elephant Speaks Jazz
        </a>
        <a rel="contents" href="#pajamagame">
          <img src="/images/pajama/IMG_3633-Overhouse.jpg" width="200px" alt="The Pajama Game" />
          <br />
          The Pajama Game
        </a>
        <a rel="contents" href="#vulnerableyear">
          <img src="/images/tvy/sprinkler_thumb.jpg" width="200px" alt="The Vulnerable Year" />
          <br />
          The Vulnerable Year
        </a>
        <a rel="contents" href="#ritual">
          <img src="/images/RoB/RoBcastrate.jpg" width="200px" alt="Ritual of Becoming" />
          <br />
          Ritual of Becoming
        </a>
        <a rel="contents" href="#mary-stuart">
          <img src="/images/mary/3L9A3103-X2.jpg" width="200px" alt="Mary Stuart" />
          <br />
          Mary Stuart
        </a>
        <a rel="contents" href="#spring-awakening">
          <img src="/images/spring/moritzilse.jpg" width="200px" alt="Spring Awakening" />
          <br />
          Spring Awakening
        </a>
        <a rel="contents" href="#telling">
          <img src="/images/telling/stanford_leslie.jpg" width="200px" alt="The Telling Project" />
          <br />
          The Telling Project
        </a>
        <a rel="contents" href="#itw">
          <img src="/images/ITW_gallery/2.1love.jpg" width="200px" alt="In The Wake" />
          <br />
          In The Wake
        </a>
        <a rel="contents" href="#ak">
          <img src="/images/annakate_gallery/irunwithyou3.JPG" width="200px" alt="Anna/Kate" />
          <br />I Run With You
        </a>
        <a rel="contents" href="#dwf">
          <img src="/images/DWF_gallery/Web_Y1A0673.jpg" width="200px" alt="Dijla Wal Furat" />
          <br />
          Dijla Wal Furat
        </a>
        <a rel="contents" href="#faith">
          <img src="/images/faith/faith85crop.png" width="200px" alt="faith" />
          <br />
          faith
        </a>
        <a rel="contents" href="#writopia">
          <img src="/images/writopia/fitz1.jpg" width="200px" alt="Writopia" />
          <br />
          Writopia
        </a>
        <a rel="contents" href="#goliath">
          <img src="/images/goliath_gallery/vietnam.jpg" width="200px" alt="Goliath" />
          <br />
          Goliath
        </a>
      </div>

      {/*The Cave*/}

      <hr className="post" />

      <div className="gallery-header">
        <h3 id="cave">
          <em>The Cave</em> at A Red Orchid Theatre
        </h3>
        A world premiere by Sadieh Rifai.
        <br />
        Featuring Aaliyah Montana, Ashley Neal, Guy Van Swearingen, H. Adoni Esho, John Judd, Kirsten Fitzgerald, Milla
        Liss, Natalie West, and Omer Abbas Salem.
        <br /> Scenic Design by Sotirios Livaditis, Lighting Design by Mike Durst, Sound Design by Joe Court, Projection
        Design by Eme Ospina-López, Costume Design by Kotryna Hilko, Wig Design by Hannah Andruss, Prop Design by Julia
        Alvarez.
        <br /> Stage Management by JC Widman with Faith Locke and Casey Fort. <br />Assistant Direction and Intimacy Direction
        by Victoria Nassif, Dramaturgy by Yasmin Zacaria Mikhaiel, Cultural Consulting and Dialect Coaching by Ronnie
        Malley. <br />Production Management by Patrick Starner, Technical Direction by Tom Daniel.
        <br /> Photos by Evan Hanover.
        <br />
      </div>
      <div className="gallery-wrapper">
        <a
          rel="cave"
          href="/images/cave/Cave-10.jpg"
          className="swipebox"
          title="H. Adoni Esho and Milla Liss in The Cave"
        >
          <img src="/images/cave/Cave-10.jpg" width="300px" alt="H. Adoni Esho and Milla Liss" />
        </a>
        <a
          rel="cave"
          href="/images/cave/Cave-12.jpg"
          className="swipebox"
          title="Milla Liss, H. Adoni Esho, Aaliyah Montana, and Kirsten Fitzgerald in The Cave"
        >
          <img
            src="/images/cave/Cave-12.jpg"
            width="300px"
            alt="Milla Liss, H. Adoni Esho, Aaliyah Montana, and Kirsten Fitzgerald"
          />
        </a>
        <a
          rel="cave"
          href="/images/cave/Cave-16.jpg"
          className="swipebox"
          title="Milla Liss, H. Adoni Esho, Kirsten Fitzgerald and Aaliyah Montana in The Cave"
        >
          <img
            src="/images/cave/Cave-16.jpg"
            width="300px"
            alt="Milla Liss, H. Adoni Esho, Kirsten Fitzgerald and Aaliyah Montana"
          />
        </a>

        <a
          rel="cave"
          href="/images/cave/Cave-21.jpg"
          className="swipebox"
          title="Ashley Neal and Kirsten Fitzgerald in The Cave"
        >
          <img src="/images/cave/Cave-21.jpg" width="300px" alt="Ashley Neal and Kirsten Fitzgerald" />
        </a>
        <a
          rel="cave"
          href="/images/cave/Cave-27.jpg"
          className="swipebox"
          title="H. Adoni Esho and Aaliyah Montana in The Cave"
        >
          <img src="/images/cave/Cave-27.jpg" width="300px" alt="H. Adoni Esho and Aaliyah Montana" />
        </a>
        <a
          rel="cave"
          href="/images/cave/Cave-38.jpg"
          className="swipebox"
          title="Guy Van Swearingen and Aaliyah Montana in The Cave"
        >
          <img src="/images/cave/Cave-38.jpg" width="300px" alt="Guy Van Swearingen and Aaliyah Montana" />
        </a>
        <a rel="cave" href="/images/cave/Cave-42.jpg" className="swipebox" title="Kirsten Fitzgerald in The Cave">
          <img src="/images/cave/Cave-42.jpg" width="300px" alt="Kirsten Fitzgerald in The Cave" />
        </a>
        <a
          rel="cave"
          href="/images/cave/Cave-47.jpg"
          className="swipebox"
          title="Milla Liss and Aaliyah Montana in The Cave"
        >
          <img src="/images/cave/Cave-47.jpg" width="300px" alt="Milla Liss and Aaliyah Montana" />
        </a>
        <a
          rel="cave"
          href="/images/cave/Cave-50.jpg"
          className="swipebox"
          title="Kirsten Fitzgerald and H. Adoni Esho in The Cave"
        >
          <img src="/images/cave/Cave-50.jpg" width="300px" alt="Kirsten Fitzgerald and H. Adoni Esho" />
        </a>
        <a
          rel="cave"
          href="/images/cave/Cave-52.jpg"
          className="swipebox"
          title="Natalie West, John Judd, and Aaliyah Montana in The Cave"
        >
          <img src="/images/cave/Cave-52.jpg" width="300px" alt="Natalie West, John Judd, and Aaliyah Montana" />
        </a>
        <a
          rel="cave"
          href="/images/cave/Cave-55.jpg"
          className="swipebox"
          title="Omer Abbas Salem, H. Adoni Esho and Aaliyah Montana in The Cave"
        >
          <img src="/images/cave/Cave-55.jpg" width="300px" alt="Omer Abbas Salem, H. Adoni Esho and Aaliyah Montana" />
        </a>
      </div>

      {/*Lysistrata*/}

      <hr className="post" />

      <div className="gallery-header">
        <h3 id="lysistrata">
          <em>Lysistrata</em> at The Theatre School at DePaul University.
        </h3>
        Written by Ellen McLaughlin.
        <br />
        Featuring BFA and MFA Performance students in The Theatre School.
        Costume Design by Spencer Harrigan, Lighting Design by Alex Elko, Sound Design
        by Dylan Kintner, Scenic Design concept by Sofia Fillon, Assistant Scenic Design by Squid Berk,
        Technical Direction by Aspen Lear, Stage management by Isabelle Smith and Bruno Díaz Miranda,
        Assistant Direction & Pre-Show Direction by Chaz Haines and Isha Pati. Theater Photos by Anna Rapaport. Staircase photos by Chaz Haines.
        <br />
      </div>
      <div className="gallery-wrapper">
        <a
          rel="lysistrata"
          href="/images/lysistrata/lysistrata-09.jpg"
          className="swipebox"
          title="Cookie Isenberg and Maeve Bradler in Lysistrata"
        >
          <img src="/images/lysistrata/lysistrata-09.jpg" width="300px" alt="Cookie Isenberg and Maeve Gomez Bradler" />
        </a>
        <a
          rel="lysistrata"
          href="/images/lysistrata/lysistrata-08.jpg"
          className="swipebox"
          title="Liam Bouza Diaz and ensemble in Lysistrata"
        >
          <img
            src="/images/lysistrata/lysistrata-08.jpg"
            width="300px"
            alt="Liam Bouza Diaz"
          />
        </a>
        <a
          rel="lysistrata"
          href="/images/lysistrata/lysistrata-05.jpg"
          className="swipebox"
          title="Evan Ozer and Marlee Feacher in Lysistrata"
        >
          <img
            src="/images/lysistrata/lysistrata-05.jpg"
            width="300px"
            alt="Evan Ozer and Marlee Feacher"
          />
        </a>
        <a
          rel="lysistrata"
          href="/images/lysistrata/CRlysistrata_3.jpg"
          className="swipebox"
          title="Marlee Feacher and P-Jay Adams in Lysistrata"
        >
          <img
            src="/images/lysistrata/CRlysistrata_3.jpg"
            width="300px"
            alt="Marlee Feacher and P-Jay Adams"
          />
        </a>
        <a
          rel="lysistrata"
          href="/images/lysistrata/CRlysistrata_2.jpg"
          className="swipebox"
          title="Marlee Feacher, Liam Bouza Diaz and ensemble in Lysistrata"
        >
          <img
            src="/images/lysistrata/CRlysistrata_2.jpg"
            width="300px"
            alt="Marlee Feacher, Liam Bouza Diaz and ensemble"
          />
        </a>

        <a
          rel="lysistrata"
          href="/images/lysistrata/lysistrata-01.jpg"
          className="swipebox"
          title="Eric Jacobson and Audrey Romero in Lysistrata"
        >
          <img src="/images/lysistrata/lysistrata-01.jpg" width="300px" alt="Eric Jacobson and Audrey Romero" />
        </a>

        <a
          rel="lysistrata"
          href="/images/lysistrata/preshow_gods.jpg"
          className="swipebox"
          title="Audrey Romero, Quintin Craig and Preston Everett in Lysistrata"
        >
          <img src="/images/lysistrata/gods_thumb.jpg" width="300px" alt="Audrey Romero, Quintin Craig and Preston Everett" />
        </a>
        <a
          rel="lysistrata"
          href="/images/lysistrata/preshow_ladies.jpg"
          className="swipebox"
          title="Audrey Romero and P-Jay Adams in Lysistrata"
        >
          <img src="/images/lysistrata/preshow_ladies.jpg" width="300px" alt="Audrey Romero and P-Jay Adams" />
        </a>
        <a
          rel="lysistrata"
          href="/images/lysistrata/preshow_fisherwoman.jpg"
          className="swipebox"
          title="Liam Bouza Diaz and Maeve Gomez Bradler in Lysistrata"
        >
          <img src="/images/lysistrata/preshow_fisherwoman.jpg" width="300px" alt="Liam Bouza Diaz and Maeve Gomez Bradler" />
        </a>
        <a
          rel="lysistrata"
          href="/images/lysistrata/preshow_soldiers.jpg"
          className="swipebox"
          title="Evan Ozer, Eric Jacobson, and Javier Muñoz in Lysistrata"
        >
          <img src="/images/lysistrata/preshow_soldiers.jpg" width="300px" alt="Evan Ozer, Eric Jacobson, and Javier Muñoz" />
        </a>
        <a
          rel="lysistrata"
          href="/images/lysistrata/preshow_cookie.jpg"
          className="swipebox"
          title="Cookie Isenberg and ensemble in Lysistrata"
        >
          <img src="/images/lysistrata/preshow_cookie.jpg" width="300px" alt="Cookie Isenberg and ensemble" />
        </a>
        <a
          rel="lysistrata"
          href="/images/lysistrata/preshow_list.jpg"
          className="swipebox"
          title="Cookie Isenberg in Lysistrata"
        >
          <img src="/images/lysistrata/preshow_list.jpg" width="300px" alt="Cookie Isenberg" />
        </a>
      </div>

      {/*Rachel*/}
      <hr className="post" />

      <div className="gallery-header">
        <h3 id="rachel">
          <em>My Name is Rachel Corrie</em> presented by The Metal Shop Performance Lab
        </h3>
        Taken from the writings of Rachel Corrie. Edited by Katherine Viner and Alan Rickman. <br />June 2024 performance presented in partnership with Alley Cat Studios and Orchids True Blue. Special thanks to
        Matt Martin.
        <br />
        August 2024 performance generously hosted by Bill Ayers.
        <br />
        Performed by Emiley Kiser, Design by Daphne Agosin with Scenic Painting by Kandi Jamieson.
        <br />
        Photos by Agnes Voltz and Dakota Sillyman.
        <br />
      </div>
      <div className="gallery-wrapper">
        <a
          rel="rachel"
          href="/images/rachel/SAM02398.jpg"
          className="swipebox"
          title="Emiley Kiser in My Name is Rachel Corrie"
        >
          <img src="/images/rachel/SAM02398.jpg" width="300px" alt="Emiley Kiser in My Name is Rachel Corrie" />
        </a>
        <a
          rel="rachel"
          href="/images/rachel/SAM02436.jpg"
          className="swipebox"
          title="Emiley Kiser in My Name is Rachel Corrie"
        >
          <img src="/images/rachel/SAM02436.jpg" width="300px" alt="Emiley Kiser in My Name is Rachel Corrie" />
        </a>
        <a
          rel="rachel"
          href="/images/rachel/SAM02474.jpg"
          className="swipebox"
          title="Emiley Kiser in My Name is Rachel Corrie"
        >
          <img src="/images/rachel/SAM02474.jpg" width="300px" alt="Emiley Kiser in My Name is Rachel Corrie" />
        </a>

        <a
          rel="rachel"
          href="/images/rachel/SAM02520.jpg"
          className="swipebox"
          title="Emiley Kiser in My Name is Rachel Corrie"
        >
          <img src="/images/rachel/SAM02520.jpg" width="300px" alt="Emiley Kiser in My Name is Rachel Corrie" />
        </a>
        <a
          rel="rachel"
          href="/images/rachel/SAM02551.jpg"
          className="swipebox"
          title="Emiley Kiser in My Name is Rachel Corrie"
        >
          <img src="/images/rachel/emiley2.jpg" width="300px" alt="Emiley Kiser in My Name is Rachel Corrie" />
        </a>
        <a
          rel="rachel"
          href="/images/rachel/SAM02560.jpg"
          className="swipebox"
          title="Emiley Kiser in My Name is Rachel Corrie"
        >
          <img src="/images/rachel/SAM02560.jpg" width="300px" alt="Emiley Kiser in My Name is Rachel Corrie" />
        </a>

        <a
          rel="rachel"
          href="/images/rachel/SAM02809.jpg"
          className="swipebox"
          title="Emiley Kiser in My Name is Rachel Corrie"
        >
          <img src="/images/rachel/SAM02809.jpg" width="300px" alt="Emiley Kiser in My Name is Rachel Corrie" />
        </a>

        <a
          rel="rachel"
          href="/images/rachel/SAM02868.jpg"
          className="swipebox"
          title="Emiley Kiser in My Name is Rachel Corrie"
        >
          <img src="/images/rachel/SAM02868.jpg" width="300px" alt="Emiley Kiser in My Name is Rachel Corrie" />
        </a>
        <a
          rel="rachel"
          href="/images/rachel/SAM02887.jpg"
          className="swipebox"
          title="Emiley Kiser in My Name is Rachel Corrie"
        >
          <img src="/images/rachel/emiley3.jpg" width="300px" alt="Emiley Kiser in My Name is Rachel Corrie" />
        </a>

        <a
          rel="rachel"
          href="/images/rachel/SAM02912.jpg"
          className="swipebox"
          title="Emiley Kiser in My Name is Rachel Corrie"
        >
          <img src="/images/rachel/emiley4.jpg" width="300px" alt="Emiley Kiser in My Name is Rachel Corrie" />
        </a>
        <a
          rel="rachel"
          href="/images/rachel/SAM02931.jpg"
          className="swipebox"
          title="Emiley Kiser in My Name is Rachel Corrie"
        >
          <img src="/images/rachel/SAM02931.jpg" width="300px" alt="Emiley Kiser in My Name is Rachel Corrie" />
        </a>
        <a
          rel="rachel"
          href="/images/rachel/SAM02474.jpg"
          className="swipebox"
          title="Emiley Kiser in My Name is Rachel Corrie"
        >
          <img src="/images/rachel/set.jpg" width="300px" alt="Set for My Name is Rachel Corrie" />
        </a>
        <a
          rel="rachel"
          href="/images/rachel/IMG_8893.jpg"
          className="swipebox"
          title="Emiley Kiser in My Name is Rachel Corrie"
        >
          <img src="/images/rachel/sky.jpg" width="300px" alt="Emiley Kiser in My Name is Rachel Corrie" />
        </a>
        <a
          rel="rachel"
          href="/images/rachel/IMG_8912.jpg"
          className="swipebox"
          title="Emiley Kiser in My Name is Rachel Corrie"
        >
          <img src="/images/rachel/pen.jpg" width="300px" alt="Emiley Kiser in My Name is Rachel Corrie" />
        </a>
        <a
          rel="rachel"
          href="/images/rachel/IMG_8870.jpg"
          className="swipebox"
          title="Emiley Kiser in My Name is Rachel Corrie"
        >
          <img src="/images/rachel/bluebg.jpg" width="300px" alt="Emiley Kiser in My Name is Rachel Corrie" />
        </a>
        <a
          rel="rachel"
          href="/images/rachel/IMG_8884.jpg"
          className="swipebox"
          title="Emiley Kiser in My Name is Rachel Corrie"
        >
          <img src="/images/rachel/doves.jpg" width="300px" alt="Emiley Kiser in My Name is Rachel Corrie" />
        </a>
        <a
          rel="rachel"
          href="/images/rachel/IMG_8885.jpg"
          className="swipebox"
          title="Kandi Jamieson as an audience reader in My Name is Rachel Corrie"
        >
          <img src="/images/rachel/kandi.jpg" width="300px" alt="Kandi Jamieson" />
        </a>
        <a
          rel="rachel"
          href="/images/rachel/IMG_8923.jpg"
          className="swipebox"
          title="Bill Ayers as an audience reader in My Name is Rachel Corrie"
        >
          <img src="/images/rachel/bill.jpg" width="300px" alt="Bill Ayers" />
        </a>
        <a
          rel="rachel"
          href="/images/rachel/IMG_8868.jpg"
          className="swipebox"
          title="Audience reader in My Name is Rachel Corrie"
        >
          <img src="/images/rachel/nadia.jpg" width="300px" alt="Audience reader" />
        </a>
        <a
          rel="rachel"
          href="/images/rachel/IMG_8928.jpg"
          className="swipebox"
          title="Matt Martin as an audience reader in My Name is Rachel Corrie"
        >
          <img src="/images/rachel/matt2.jpg" width="300px" alt="Matt Martin" />
        </a>
        <a
          rel="rachel"
          href="/images/rachel/IMG_8891.jpg"
          className="swipebox"
          title="Daphne Agosin as an audience reader in My Name is Rachel Corrie"
        >
          <img src="/images/rachel/daphne.jpg" width="300px" alt="Daphne Agosin" />
        </a>
        <a
          rel="rachel"
          href="/images/rachel/IMG_8892.jpg"
          className="swipebox"
          title="Audience reader in My Name is Rachel Corrie"
        >
          <img src="/images/rachel/reader1.jpg" width="300px" alt="Audience reader" />
        </a>
        <a
          rel="rachel"
          href="/images/rachel/IMG_8930.jpg"
          className="swipebox"
          title="Audience reader in My Name is Rachel Corrie"
        >
          <img src="/images/rachel/sadieh.jpg" width="300px" alt="Audience reader" />
        </a>
        <a
          rel="rachel"
          href="/images/rachel/IMG_8925.jpg"
          className="swipebox"
          title="Audience reader in My Name is Rachel Corrie"
        >
          <img src="/images/rachel/katherine.jpg" width="300px" alt="Audience reader" />
        </a>
        <a
          rel="rachel"
          href="/images/rachel/IMG_8926.jpg"
          className="swipebox"
          title="Audience reader in My Name is Rachel Corrie"
        >
          <img src="/images/rachel/jen.jpg" width="300px" alt="Audience reader" />
        </a>
      </div>

      {/*Witch*/}
      <hr className="post" />

      <div className="gallery-header">
        <h3 id="witch">
          <em>Witch</em> at The Theatre School at DePaul University
        </h3>
        Written by Jen Silverman.
        <br />Costume Design by Naomi Arroyo, Scenic Design by Elizabeth Hoffhines, Lighting Design by Sam Eads, Sound Design
        by Mia Howard. Stage management by Alondra Vela and Shreya Khanna, assistant directed by Emma Burkey and Perse
        Grammer, technical direction by Alex Klose, production management by Amelia Osborne. Photos by Sam Eads.
        <br />
      </div>
      <div className="gallery-wrapper">
        <a rel="witch" href="/images/witch/gavin.jpg" className="swipebox" title="Gavin Bradler in Witch">
          <img src="/images/witch/witch_thumbs/gavin.jpg" width="300px" alt="Gavin Bradler in Witch" />
        </a>
        <a
          rel="witch"
          href="/images/witch/bar.jpg"
          className="swipebox"
          title="Noah Solomon Whittiker and Maeve Gomez Bradler in Witch"
        >
          <img
            src="/images/witch/witch_thumbs/bar.jpg"
            width="300px"
            alt="Noah Solomon Whittiker and Maeve Gomez Bradler in Witch"
          />
        </a>
        <a
          rel="witch"
          href="/images/witch/mydad.jpg"
          className="swipebox"
          title="Mark Bellamare and Noah Solomon Whittiker in Witch"
        >
          <img
            src="/images/witch/witch_thumbs/mydad.jpg"
            width="300px"
            alt="Mark Bellamare and Noah Solomon Whittiker in Witch"
          />
        </a>

        <a
          rel="witch"
          href="/images/witch/potluck.jpg"
          className="swipebox"
          title="Maeve Gomez Bradler and Chloe Rodriques in Witch"
        >
          <img
            src="/images/witch/witch_thumbs/potluck.jpg"
            width="300px"
            alt="Maeve Gomez Bradler and Chloe Rodriques in Witch"
          />
        </a>
        <a
          rel="witch"
          href="/images/witch/flood.jpg"
          className="swipebox"
          title="Maeve Gomez Bradler and Chloe Rodriques in Witch"
        >
          <img
            src="/images/witch/witch_thumbs/flood.jpg"
            width="300px"
            alt="Maeve Gomez Bradler and Chloe Rodriques in Witch"
          />
        </a>
        <a rel="witch" href="/images/witch/cuddysmom.jpg" className="swipebox" title="Eliot Hall in Witch">
          <img src="/images/witch/witch_thumbs/cuddysmom.jpg" width="300px" alt="Eliot Hall in Witch" />
        </a>
        <a
          rel="witch"
          href="/images/witch/bella.jpg"
          className="swipebox"
          title="Bella Kelso and Noah Solomon Whittiker in Witch"
        >
          <img
            src="/images/witch/witch_thumbs/bella.jpg"
            width="300px"
            alt="Bella Kelso and Noah Solomon Whittiker in Witch"
          />
        </a>
        <a
          rel="witch"
          href="/images/witch/noah.jpg"
          className="swipebox"
          title="Bella Kelso and Noah Solomon Whittiker in Witch"
        >
          <img
            src="/images/witch/witch_thumbs/noah.jpg"
            width="300px"
            alt="Bella Kelso and Noah Solomon Whittiker in Witch"
          />
        </a>

        <a
          rel="witch"
          href="/images/witch/handsoff.jpg"
          className="swipebox"
          title="Eliot Hall and Bella Kelso in Witch"
        >
          <img src="/images/witch/witch_thumbs/handsoff.jpg" width="300px" alt="Eliot Hall and Bella Kelso in Witch" />
        </a>
        <a
          rel="witch"
          href="/images/witch/floor.jpg"
          className="swipebox"
          title="Maeve Gomez Bradler and Chloe Rodriques in Witch"
        >
          <img
            src="/images/witch/witch_thumbs/floor.jpg"
            width="300px"
            alt="Maeve Gomez Bradler and Chloe Rodriques in Witch"
          />
        </a>
        <a
          rel="witch"
          href="/images/witch/nothuman.jpg"
          className="swipebox"
          title="Maeve Gomez Bradler and Chloe Rodriques in Witch"
        >
          <img
            src="/images/witch/witch_thumbs/nothuman.jpg"
            width="300px"
            alt="Maeve Gomez Bradler and Chloe Rodriques in Witch"
          />
        </a>
        <a rel="witch" href="/images/witch/eliot.jpg" className="swipebox" title="Eliot Hall in Witch">
          <img src="/images/witch/witch_thumbs/eliot.jpg" width="300px" alt="Eliot Hall in Witch" />
        </a>
        <a
          rel="witch"
          href="/images/witch/heir.jpg"
          className="swipebox"
          title="Bella Kelso and Noah Solomon Whittiker in Witch"
        >
          <img
            src="/images/witch/witch_thumbs/heir.jpg"
            width="300px"
            alt="Bella Kelso and Noah Solomon Whittiker in Witch"
          />
        </a>
        <a
          rel="witch"
          href="/images/witch/fightyou.jpg"
          className="swipebox"
          title="Mark Bellamare and Noah Solomon Whittiker in Witch"
        >
          <img
            src="/images/witch/witch_thumbs/fightyou.jpg"
            width="300px"
            alt="Mark Bellamare and Noah Solomon Whittiker in Witch"
          />
        </a>
        <a
          rel="witch"
          href="/images/witch/strangle.jpg"
          className="swipebox"
          title="Mark Bellamare and Noah Solomon Whittiker in Witch"
        >
          <img
            src="/images/witch/witch_thumbs/strangle.jpg"
            width="300px"
            alt="Mark Bellamare and Noah Solomon Whittiker in Witch"
          />
        </a>
        <a rel="witch" href="/images/witch/chloe.jpg" className="swipebox" title="Chloe Rodriques in Witch">
          <img src="/images/witch/witch_thumbs/chloe.jpg" width="300px" alt="Chloe Rodriques in Witch" />
        </a>
        <a rel="witch" href="/images/witch/Gavin aria 3.jpg" className="swipebox" title="Maeve Gomez Bradler in Witch">
          <img src="/images/witch/witch_thumbs/Gavin aria 3.jpg" width="300px" alt="Maeve Gomez Bradler in Witch" />
        </a>
      </div>

      {/*The Elephant Speaks Jazz*/}
      <hr className="post" />

      <div className="gallery-header">
        <h3 id="elephant">
          <em>The Elephant Speaks Jazz</em> at Viterbo University
        </h3>
        Performed by the BFA Theater and Music Theater students of the Conservatory for the Performing Arts
        <br />
        Presented with a grant from the Jim Henson Foundation in collaboration with WonderStruck Theatre Co. as part of
        the Viterbo University New Works Festival.
        <br />
        Concept, Puppetry Design and Scenic Design by Eric Van Wyk, Original Music by Luke Thering, Lighting Design by
        Justin Thomas, Costume Design by Irina Christel, Sound Design by Brett Huus Photos by Apropos Photography
        <br />
      </div>
      <div className="gallery-wrapper">
        <a
          rel="elephant"
          href="/images/elephant/_PRO2187-2_1200px.jpg"
          className="swipebox"
          title="The Elephant Speaks Jazz: At Home [Aren Alexander-Battee, Bri Reilly, Lindsay Meath, Spencer Curtis, Erin Milleville, Lukas Nederloe, and Puppeteers - Jessica Schneider, Mikala Clark, Grace Reynolds]"
        >
          <img
            src="/images/elephant/_PRO2187-2_1200px.jpg"
            width="300px"
            alt="At Home [Aren Alexander-Battee, Bri Reilly, Lindsay Meath, Spencer Curtis, Erin Milleville, Lukas Nederloe, and Puppeteers - Jessica Schneider, Mikala Clark, Grace Reynolds]"
          />
        </a>
        <a
          rel="elephant"
          href="/images/elephant/_APR3679-2_1200px.jpg"
          className="swipebox"
          title="The Elephant Speaks Jazz: Miss Viola meets Ellie"
        >
          <img src="/images/elephant/_APR3679-2_1200px.jpg" width="300px" alt="Miss Viola meets Ellie" />
        </a>
        <a
          rel="elephant"
          href="/images/elephant/_APR3730_1200px.jpg"
          className="swipebox"
          title="The Elephant Speaks Jazz: Tug of War [Aren Alexander-Battee, Bri Reilly, and Puppeteers - Jessica Schneider, Mikala Clark, Spencer Curtis]"
        >
          <img
            src="/images/elephant/_APR3730_1200px.jpg"
            width="300px"
            alt="Tug of War [Aren Alexander-Battee, Bri Reilly, and Puppeteers - Jessica Schneider, Mikala Clark, Spencer Curtis]"
          />
        </a>
        <a
          rel="elephant"
          href="/images/elephant/_APR4099_1200px.jpg"
          className="swipebox"
          title="The Elephant Speaks Jazz: Disguises! [Aren Alexander-Battee, Bri Reilly, Erin Milleville, Lukas Nederloe, and Puppeteers - Jessica Schneider, Mikala Clark, Spencer Curtis]"
        >
          <img
            src="/images/elephant/_APR4099_1200px.jpg"
            width="300px"
            alt="Disguises! [Aren Alexander-Battee, Bri Reilly, Erin Milleville, Lukas Nederloe, and Puppeteers - Jessica Schneider, Mikala Clark, Spencer Curtis]"
          />
        </a>
        <a
          rel="elephant"
          href="/images/elephant/_APR4003_1200px.jpg"
          className="swipebox"
          title="The Elephant Speaks Jazz: Sad Clowns [Erin Milleville & Lukas Nederloe]"
        >
          <img
            src="/images/elephant/_APR4003_1200px.jpg"
            width="300px"
            alt="Sad Clowns [Erin Milleville & Lukas Nederloe]"
          />
        </a>
        <a
          rel="elephant"
          href="/images/elephant/_APR3850_1200px.jpg"
          className="swipebox"
          title="The Elephant Speaks Jazz: Ellie Trumpets [Jon Ailabouni and Puppeteers - Jessica Schneider, Mikala Clark, Grace Reynolds]"
        >
          <img
            src="/images/elephant/_APR3850_1200px.jpg"
            width="300px"
            alt="Ellie Trumpets [Jessica Schneider, Mikala Clark, Grace Reynolds, and Jon Ailabouni]"
          />
        </a>

        <a
          rel="elephant"
          href="/images/elephant/_PRO1922_1200px.jpg"
          className="swipebox"
          title="The Elephant Speaks Jazz: Chase: [Aren Alexander-Battee, Bri Reilly, Kjersten Danzig]"
        >
          <img
            src="/images/elephant/_PRO1922_1200px.jpg"
            width="300px"
            alt="Chase [Aren Alexander-Battee, Bri Reilly, Kjersten Danzig]"
          />
        </a>
        <a
          rel="elephant"
          href="/images/elephant/_PRO2319-2_1200px.jpg"
          className="swipebox"
          title="The Elephant Speaks Jazz: Swimming [Aren Alexander-Battee, Bri Reilly, Erin Milleville, Lukas Nederloe]"
        >
          <img
            src="/images/elephant/_PRO2319-2_1200px.jpg"
            width="300px"
            alt="Swimming [Aren Alexander-Battee, Bri Reilly, Erin Milleville, Lukas Nederloe]"
          />
        </a>
      </div>

      <hr className="post" />

      <div className="gallery-header">
        <h3 id="pajamagame">
          <em>The Pajama Game</em> at Viterbo University
        </h3>
        Performed by the BFA Theater and Music Theater students of the Conservatory for the Performing Arts
        <br />
        Scenic design by Yuri Okahana-Benson, lighting design by Patrick Strain, costume design by Jen Brown, audio
        engineering by Douglas Wilken
        <br />
        Photos by Dylan Overhouse Productions
      </div>
      <div className="gallery-wrapper">
        <a
          rel="pajamagame"
          href="/images/pajama/IMG_3298-Overhouse.jpg"
          className="swipebox"
          title="Racing With The Clock: Ensemble - The Pajama Game"
        >
          <img src="/images/pajama/IMG_3291-Overhouse.jpg" width="300px" alt="Racing With The Clock" />
        </a>
        <a
          rel="pajamagame"
          href="/images/pajama/IMG_3594-Overhouse.jpg"
          className="swipebox"
          title="Hey There: Jack Scharrer - The Pajama Game"
        >
          <img src="/images/pajama/IMG_3594-Overhouse.jpg" width="300px" alt="Hey There" />
        </a>
        <a
          rel="pajamagame"
          href="/images/pajama/IMG_3676-Overhouse.jpg"
          className="swipebox"
          title="Small Talk: Jack Scharrer and Natalie Davies - The Pajama Game"
        >
          <img src="/images/pajama/IMG_3685-Overhouse.jpg" width="300px" alt="Small Talk" />
        </a>

        <a
          rel="pajamagame"
          href="/images/pajama/IMG_3728-Overhouse.jpg"
          className="swipebox"
          title="Steam Heat: Payton Harper, Rachael Anderson, Iraya Catalina Cress and ensemble - The Pajama Game"
        >
          <img src="/images/pajama/IMG_3728-Overhouse.jpg" width="300px" alt="Steam Heat" />
        </a>
        <a
          rel="pajamagame"
          href="/images/pajama/IMG_3506-Overhouse_cropped.jpg"
          className="swipebox"
          title="Hernando's Hideaway: Rachael Armstrong and Jack Scharrer - The Pajama Game"
        >
          <img src="/images/pajama/IMG_3506-Overhouse_cropped.jpg" width="300px" alt="Hernando's Hideaway" />
        </a>
        <a
          rel="pajamagame"
          href="/images/pajama/IMG_3533-Overhouse.jpg"
          className="swipebox"
          title="Hernando's Hideaway: Rachael Armstrong and Jack Scharrer - The Pajama Game"
        >
          <img src="/images/pajama/IMG_3533-Overhouse.jpg" width="300px" alt="Hernando's Hideaway" />
        </a>
        <a
          rel="pajamagame"
          href="/images/pajama/IMG_3633-Overhouse.jpg"
          className="swipebox"
          title="Hernando's Hideaway: Ensemble - The Pajama Game"
        >
          <img src="/images/pajama/IMG_3633-Overhouse.jpg" width="300px" alt="Hernando's Hideaway" />
        </a>
        <a
          rel="pajamagame"
          href="/images/pajama/IMG_3389-Overhouse.jpg"
          className="swipebox"
          title="7.5 Cents: Evan Schmit, Natalie Davies and Ensemble - The Pajama Game"
        >
          <img src="/images/pajama/IMG_3389-Overhouse.jpg" width="300px" alt="7.5 Cents" />
        </a>
        <a
          rel="pajamagame"
          href="/images/pajama/IMG_3466-Overhouse.jpg"
          className="swipebox"
          title="Ensemble in The Pajama Game Finale"
        >
          <img src="/images/pajama/IMG_3466-Overhouse.jpg" width="300px" alt="Ensemble" />
        </a>
      </div>

      <hr className="post" />
      {/*The Vulnerable Year*/}
      <div className="gallery-header">
        <h3 id="vulnerableyear">
          <em>The Vulnerable Year</em> with The Metal Shop Performance Lab
        </h3>
        <p>
          In-process invited Zoom workshop
          <br />A participatory, virtual performance event that sought to create community through the power of
          vulnerability. <br />
          Co-created by Grace Dolezal-Ng (performer), Alex Mallory (director) and Maria Simpkins (writer).
          <br />
          <a href="https://youtu.be/2s7Tj8L3Vqo" target="_blank" rel="noreferrer">
            Click here
          </a>{' '}
          to watch the prologue.
        </p>
      </div>
      <div className="gallery-wrapper">
        <a
          rel="vulnerableyear"
          href="/images/tvy/candle.jpg"
          className="swipebox"
          title="The Vulnerable Year development workshop"
        >
          <img src="/images/tvy/candle.jpg" width="300px" alt="#5 Candle" />
        </a>
        <a
          rel="vulnerableyear"
          href="/images/tvy/2screen.jpg"
          className="swipebox"
          title="Grace Dolezal-Ng in The Vulnerable Year development workshop"
        >
          <img src="/images/tvy/2screen.jpg" width="300px" alt="Grace Dolezal-Ng" />
        </a>
        <a
          rel="vulnerableyear"
          href="/images/tvy/participation.jpg"
          className="swipebox"
          title="Audience participation in The Vulnerable Year development workshop"
        >
          <img src="/images/tvy/participation.jpg" width="300px" alt="Audience participation" />
        </a>
        <a
          rel="vulnerableyear"
          href="/images/tvy/postits.jpg"
          className="swipebox"
          title="Grace Dolezal-Ng in The Vulnerable Year development workshop"
        >
          <img src="/images/tvy/postits.jpg" width="300px" alt="Grace Dolezal-Ng" />
        </a>
        <a
          rel="vulnerableyear"
          href="/images/tvy/sprinkler.jpg"
          className="swipebox"
          title="Grace Dolezal-Ng in The Vulnerable Year development workshop"
        >
          <img src="/images/tvy/sprinkler.jpg" width="300px" alt="Grace Dolezal-Ng" />
        </a>
      </div>

      <hr className="post" />
      {/*Ritual of Becoming*/}
      <div className="gallery-header">
        <h3 id="ritual">
          <em>Ritual of Becoming</em> with Poetic Theater Productions
        </h3>
        <p>
          A journey of radical self-acceptance and transformation through poetry and clay created by Alex Mallory, Jenny
          Pacanowski and Maria L. Salazar.
          <br />
          commissioned for Rites of Passage, part of the 2021 Poetic License Festival
          <br />
          Aired as part of the 2021 Austin Veteran Arts Festival through RokuTV
          <br />
          Lighting design by Christina Tang, projection design by Lisa Renkel and Brian Pacelli, sound design by Ray
          Archie
          <br />
          Watch video{' '}
          <a href="https://youtu.be/oqR8lunkvBw" target="_blank" rel="noreferrer">
            here
          </a>
        </p>
      </div>
      <div className="gallery-wrapper">
        <a
          rel="ritual"
          href="/images/RoB/RoBstarseeds.jpg"
          className="swipebox"
          title="Maria Salazar in Ritual of Becoming"
        >
          <img src="/images/RoB/RoBstarseeds.jpg" width="300px" alt="Maria Salazar" />
        </a>
        <a
          rel="ritual"
          href="/images/RoB/RoBcut.jpg"
          className="swipebox"
          title="Maria Salazar and Jenny Pacanowski in Ritual of Becoming"
        >
          <img src="/images/RoB/RoBcut.jpg" width="300px" alt="Maria Salazar and Jenny Pacanowski" />
        </a>
        <a
          rel="ritual"
          href="/images/RoB/RoBboysclub.jpg"
          className="swipebox"
          title="Jenny Pacanowski in Ritual of Becoming"
        >
          <img src="/images/RoB/RoBboysclub.jpg" width="300px" alt="Jenny Pacanowski" />
        </a>
        <a
          rel="ritual"
          href="/images/RoB/RoBcastrate.jpg"
          className="swipebox"
          title="Jenny Pacanowski in Ritual of Becoming"
        >
          <img src="/images/RoB/RoBcastrate.jpg" width="300px" alt="Jenny Pacanowski" />
        </a>
        <a
          rel="ritual"
          href="/images/RoB/RoBclay.jpg"
          className="swipebox"
          title="Maria Salazar and Jenny Pacanowski in Ritual of Becoming"
        >
          <img src="/images/RoB/RoBclay.jpg" width="300px" alt="Maria Salazar and Jenny Pacanowski" />
        </a>
        <a
          rel="ritual"
          href="/images/RoB/RoBscars.jpg"
          className="swipebox"
          title="Jenny Pacanowski in Ritual of Becoming"
        >
          <img src="/images/RoB/RoBscars.jpg" width="300px" alt="Jenny Pacanowski" />
        </a>
      </div>

      <hr className="post" />
      {/*Mary Stuart*/}
      <div className="gallery-header">
        <h3 id="mary-stuart">
          <em>Schiller's Mary Stuart</em> at Northwestern University
        </h3>
        <p>
          in a version by Peter Oswald
          <br />
          Featuring a cast of women and transgender actors, the production aimed to empower the performers through
          authenticity and authorship fostered in the rehearsal process and on stage.
          <br />
          Watch a 2-minute video featuring outtakes of exploratory work from the rehearsal process{' '}
          <a href="https://youtu.be/lU3-KuxBPXA" target="_blank" rel="noreferrer">
            here
          </a>
          <br />
          Lighting design by Gabrielle Strong, scenic design by Scott Penner, costume design by Drina Krlic, sound
          design by Sarah Espinoza
          <br />
          Photos by Justin Barbin Photography
          <br />
        </p>
      </div>
      <div className="gallery-wrapper">
        <a
          rel="mary-stuart"
          href="/images/mary/MaryStuart-21-X3.jpg"
          className="swipebox"
          title="Elizabeth Dickson and Mia Lennon in Mary Stuart"
        >
          <img src="/images/mary/MaryStuart-21-X3.jpg" width="300px" alt="Elizabeth Dickson and Mia Lennon" />
        </a>
        <a
          rel="mary-stuart"
          href="/images/mary/MaryStuart-25-X3.jpg"
          className="swipebox"
          title="Hannah Hakim and Elizabeth Dickson in Mary Stuart"
        >
          <img src="/images/mary/MaryStuart-25-X3.jpg" width="300px" alt="Hannah Hakim and Elizabeth Dickson" />
        </a>
        <a
          rel="mary-stuart"
          href="/images/mary/3L9A1913-X2.jpg"
          className="swipebox"
          title="Hannah Hakim and Liv Reis in Mary Stuart"
        >
          <img src="/images/mary/3L9A1913-X2.jpg" width="300px" alt="Hannah Hakim and Liv Reis" />
        </a>
        <a
          rel="mary-stuart"
          href="/images/mary/3L9A2054-X2.jpg"
          className="swipebox"
          title="Shane Eichstaedt, Valen-Marie Santos, and Juliet Wolfe in Mary Stuart"
        >
          <img
            src="/images/mary/3L9A2054-X2.jpg"
            width="300px"
            alt="Shane Eichstaedt, Valen-Marie Santos and Juliet Wolfe"
          />
        </a>
        <a
          rel="mary-stuart"
          href="/images/mary/MaryStuart-93-X3.jpg"
          className="swipebox"
          title="Chisom Diana Dimiri in Mary Stuart"
        >
          <img src="/images/mary/MaryStuart-93-X3.jpg" width="300px" alt="Chisom Diana Dimiri" />
        </a>
        <a
          rel="mary-stuart"
          href="/images/mary/MaryStuart-119-X3.jpg"
          className="swipebox"
          title="Liv Reis and Natalie Welber in Mary Stuart"
        >
          <img src="/images/mary/MaryStuart-119-X3.jpg" width="300px" alt="Liv Reis and Natalie Welber" />
        </a>
        <a
          rel="mary-stuart"
          href="/images/mary/MaryStuart-122-X3.jpg"
          className="swipebox"
          title="Natalie Welber and Juliet Wolfe in Mary Stuart"
        >
          <img src="/images/mary/MaryStuart-122-X3.jpg" width="300px" alt="Natalie Welber and Juliet Wolfe" />
        </a>
        <a
          rel="mary-stuart"
          href="/images/mary/MaryStuart-137-X3.jpg"
          className="swipebox"
          title="Hannah Hakim in Mary Stuart"
        >
          <img src="/images/mary/MaryStuart-137-X3.jpg" width="300px" alt="Hannah Hakim" />
        </a>
        <a
          rel="mary-stuart"
          href="/images/mary/3L9A2490-X2.jpg"
          className="swipebox"
          title="Mia Lennon and Hannah Hakim in Mary Stuart"
        >
          <img src="/images/mary/3L9A2490-X2.jpg" width="300px" alt="Mia Lennon and Hannah Hakim" />
        </a>
        <a
          rel="mary-stuart"
          href="/images/mary/3L9A2530-X2.jpg"
          className="swipebox"
          title="Hannah Hakim and Chisom Diana Dimiri in Mary Stuart"
        >
          <img src="/images/mary/3L9A2530-X2.jpg" width="300px" alt="Hannah Hakim and Chisom Diana Dimiri" />
        </a>
        <a
          rel="mary-stuart"
          href="/images/mary/MaryStuart-149-X3.jpg"
          className="swipebox"
          title="Hannah Hakim in Mary Stuart"
        >
          <img src="/images/mary/MaryStuart-149-X3.jpg" width="300px" alt="Hannah Hakim" />
        </a>
        <a
          rel="mary-stuart"
          href="/images/mary/3L9A2629-X2.jpg"
          className="swipebox"
          title="Juliet Wolfe and Hannah Hakim in Mary Stuart"
        >
          <img src="/images/mary/3L9A2629-X2.jpg" width="300px" alt="Juliet Wolfe and Hannah Hakim" />
        </a>
        <a
          rel="mary-stuart"
          href="/images/mary/MaryStuart-171-X3.jpg"
          className="swipebox"
          title="Juliet Wolfe and Hannah Hakim in Mary Stuart"
        >
          <img src="/images/mary/MaryStuart-171-X3.jpg" width="300px" alt="Juliet Wolfe and Hannah Hakim" />
        </a>
        <a
          rel="mary-stuart"
          href="/images/mary/MaryStuart-177-X3.jpg"
          className="swipebox"
          title="Juliet Wolfe and Hannah Hakim in Mary Stuart"
        >
          <img src="/images/mary/MaryStuart-177-X3.jpg" width="300px" alt="Juliet Wolfe and Hannah Hakim" />
        </a>
        <a
          rel="mary-stuart"
          href="/images/mary/3L9A2754-X2.jpg"
          className="swipebox"
          title="Hannah Hakim and Liv Reis in Mary Stuart"
        >
          <img src="/images/mary/3L9A2754-X2.jpg" width="300px" alt="Hannah Hakim and Liv Reis" />
        </a>
        <a
          rel="mary-stuart"
          href="/images/mary/3L9A2762-X2.jpg"
          className="swipebox"
          title="Hannah Hakim and Liv Reis in Mary Stuart"
        >
          <img src="/images/mary/3L9A2762-X2.jpg" width="300px" alt="Hannah Hakim and Liv Reis" />
        </a>
        <a
          rel="mary-stuart"
          href="/images/mary/MaryStuart-220-X3.jpg"
          className="swipebox"
          title="Shane Eichstaedt and Stella Cole in Mary Stuart"
        >
          <img src="/images/mary/MaryStuart-220-X3.jpg" width="300px" alt="Shane Eichstaedt and Stella Cole" />
        </a>
        <a
          rel="mary-stuart"
          href="/images/mary/3L9A2957-X2.jpg"
          className="swipebox"
          title="Mia Lennon, Yama Pouye, and Liv Reis in Mary Stuart"
        >
          <img src="/images/mary/3L9A2957-X2.jpg" width="300px" alt="Mia Lennon, Yama Pouye, and Liv Reis" />
        </a>
        <a
          rel="mary-stuart"
          href="/images/mary/MaryStuart-236-X3.jpg"
          className="swipebox"
          title="Stella Cole and Juliet Wolfe in Mary Stuart"
        >
          <img src="/images/mary/MaryStuart-236-X3.jpg" width="300px" alt="Stella Cole and Juliet Wolfe" />
        </a>
        <a
          rel="mary-stuart"
          href="/images/mary/3L9A3103-X2.jpg"
          className="swipebox"
          title="Juliet Wolfe in Mary Stuart"
        >
          <img src="/images/mary/3L9A3103-X2.jpg" width="300px" alt="Juliet Wolfe" />
        </a>
        <a
          rel="mary-stuart"
          href="/images/mary/MaryStuart-290-X3.jpg"
          className="swipebox"
          title="Hannah Hakim in Mary Stuart"
        >
          <img src="/images/mary/MaryStuart-290-X3.jpg" width="300px" alt="Hannah Hakim" />
        </a>
        <a
          rel="mary-stuart"
          href="/images/mary/MaryStuart-296-X3.jpg"
          className="swipebox"
          title="Shane Eichstaedt and Hannah Hakim in Mary Stuart"
        >
          <img src="/images/mary/MaryStuart-296-X3.jpg" width="300px" alt="Shane Eichstaedt and Hannah Hakim" />
        </a>
        <a
          rel="mary-stuart"
          href="/images/mary/MaryStuart-316-X3.jpg"
          className="swipebox"
          title="Hannah Hakim in Mary Stuart"
        >
          <img src="/images/mary/MaryStuart-316-X3.jpg" width="300px" alt="Hannah Hakim" />
        </a>
        <a
          rel="mary-stuart"
          href="/images/mary/MaryStuart-323-X3.jpg"
          className="swipebox"
          title="Hannah Hakim in Mary Stuart"
        >
          <img src="/images/mary/MaryStuart-323-X3.jpg" width="300px" alt="Hannah Hakim" />
        </a>
        <a
          rel="mary-stuart"
          href="/images/mary/MaryStuart-328-X3.jpg"
          className="swipebox"
          title="Juliet Wolfe in Mary Stuart"
        >
          <img src="/images/mary/MaryStuart-328-X3.jpg" width="300px" alt="Juliet Wolfe" />
        </a>
        <a
          rel="mary-stuart"
          href="/images/mary/MaryStuart-335-X3.jpg"
          className="swipebox"
          title="Chisom Diana Dimiri and Juliet Wolfe in Mary Stuart"
        >
          <img src="/images/mary/MaryStuart-335-X3.jpg" width="300px" alt="Chisom Diana Dimiri and Juliet Wolfe" />
        </a>
        <a
          rel="mary-stuart"
          href="/images/mary/MaryStuart-345-X3.jpg"
          className="swipebox"
          title="Juliet Wolfe in Mary Stuart"
        >
          <img src="/images/mary/MaryStuart-345-X3.jpg" width="300px" alt="Juliet Wolfe" />
        </a>
      </div>

      <hr className="post" />
      {/*Spring Awakening*/}
      <div className="gallery-header">
        <h3 id="spring-awakening">
          <em>Spring Awakening</em> at Northwestern University
        </h3>
        <p>
          an original translation by Alex Mallory from Frank Wedekind's <i>Frühlings Erwachen</i>
          <br />
          This production aimed to find the humor and urgency of the original text in its indictment of society for its
          role in the moral and sexual education of teenagers. The ensemble occupied the rear of the ¾ thrust stage to
          mirror the audience, as though completing the arena, and draw the audience’s attention to their act of
          witnessing.
          <br />
          Lighting design by Matt Sharp, scenic design by Woongjin Oh, costume design by Stephanie Ross, sound design by
          Grover Hollway
          <br />
          Photos by Justin Barbin Photography
        </p>
      </div>
      <div className="gallery-wrapper">
        <a
          rel="spring-awakening"
          href="/images/spring/boys.jpg"
          className="swipebox"
          title="Makasha Copeland, Hale Stewart, Jordan Moore, Jake Wallack and Adam Orme in Spring Awakening"
        >
          <img
            src="/images/spring/boys.jpg"
            width="300px"
            alt="Makasha Copeland, Hale Stewart, Jordan Moore, Jake Wallack and Adam Orme"
          />
        </a>
        <a
          rel="spring-awakening"
          href="/images/spring/moritzmelchior.jpg"
          className="swipebox"
          title="Jordan Moore and Adam Orme in Spring Awakening"
        >
          <img src="/images/spring/moritzmelchior.jpg" width="300px" alt="Jordan Moore and Adam Orme" />
        </a>
        <a
          rel="spring-awakening"
          href="/images/spring/wendlamrsB.jpg"
          className="swipebox"
          title="Katy Murphy and Juliet Wolfe in Spring Awakening"
        >
          <img src="/images/spring/wendlamrsB.jpg" width="300px" alt="Katy Murphy and Juliet Wolfe" />
        </a>
        <a
          rel="spring-awakening"
          href="/images/spring/wendla.jpg"
          className="swipebox"
          title="Juliet Wolfe in Spring Awakening"
        >
          <img src="/images/spring/wendla.jpg" width="300px" alt="Juliet Wolfe" />
        </a>
        <a
          rel="spring-awakening"
          href="/images/spring/moritzilse.jpg"
          className="swipebox"
          title="Madison Fiedler and Jordan Moore in Spring Awakening"
        >
          <img src="/images/spring/moritzilse.jpg" width="300px" alt="Madison Fiedler and Jordan Moore" />
        </a>
        <a
          rel="spring-awakening"
          href="/images/spring/moritz.jpg"
          className="swipebox"
          title="Jordan Moore in Spring Awakening"
        >
          <img src="/images/spring/moritz.jpg" width="300px" alt="Jordan Moore" />
        </a>
        <a
          rel="spring-awakening"
          href="/images/spring/teachers.jpg"
          className="swipebox"
          title="Adam Orme and ensemble in Spring Awakening"
        >
          <img src="/images/spring/teachers.jpg" width="300px" alt="Adam Orme and ensemble" />
        </a>
        <a
          rel="spring-awakening"
          href="/images/spring/melchior.jpg"
          className="swipebox"
          title="Adam Orme in Spring Awakening"
        >
          <img src="/images/spring/melchior.jpg" width="300px" alt="Adam Orme" />
        </a>
      </div>

      <hr className="post" />
      {/*Telling Project*/}
      <div className="gallery-header">
        <h3 id="telling">The Telling Project</h3>
        <p>
          Local military veterans & family members rehearse & perform their own stories for their communities. Local
          producing partners include the Naomi Ruth Cohen Institute at the Chicago School of Professional Psychology,
          Piven Theatre, The Medicine and the Muse Program at Stanford University, Bay Street Theatre, Southampton Arts
          Center, the Joseph J Theinert Foundation, and the Veteran Artist Program. For more about The Telling Project,
          click{' '}
          <a href="https://thetellingproject.org/" target="_blank" rel="noreferrer">
            here
          </a>
          <br />
        </p>
      </div>
      <div className="gallery-wrapper">
        <a
          rel="telling"
          href="/images/telling/stanford_leslie.jpg"
          className="swipebox"
          title="Leslie Bridges and ensemble of military veterans in Telling: Stanford. Photo by Federica Armstrong"
        >
          <img src="/images/telling/stanford_leslie.jpg" width="300px" alt="Leslie Bridges in Telling: Stanford" />
        </a>
        <a
          rel="telling"
          href="/images/telling/nyc_group.JPG"
          className="swipebox"
          title="The cast of NYC military veterans and family members onstage in Telling: NYC"
        >
          <img src="/images/telling/nyc_group.JPG" width="300px" alt="Telling: NYC group" />
        </a>

        <a
          rel="telling"
          href="/images/telling/shelter_perlman.jpg"
          className="swipebox"
          title="Long Island military veterans onstage in Telling: Shelter Island"
        >
          <img src="/images/telling/shelter_perlman.jpg" width="300px" alt="Telling: Shelter Island group" />
        </a>
        <a
          rel="telling"
          href="/images/telling/stanford_bobby.jpg"
          className="swipebox"
          title="Bobby McLean in Telling: Stanford. Photo by Federica Armstrong"
        >
          <img src="/images/telling/stanford_bobby.jpg" width="300px" alt="Bobby McLean in Telling: Stanford" />
        </a>
        <a
          rel="telling"
          href="/images/telling/nyc_letrice.JPG"
          className="swipebox"
          title="Letrice Coan onstage in Telling: NYC"
        >
          <img src="/images/telling/nyc_letrice.JPG" width="300px" alt="Letrice Coan in Telling: NYC" />
        </a>
        <a
          rel="telling"
          href="/images/telling/stanford_victoria.jpg"
          className="swipebox"
          title="Victoria Sanders and ensemble of military veterans and family members in Telling: Stanford. Photo by Federica Armstrong"
        >
          <img src="/images/telling/stanford_victoria.jpg" width="300px" alt="Victoria Sanders in Telling: Stanford" />
        </a>
      </div>

      <hr className="post" />
      {/*In The Wake*/}
      <div className="gallery-header">
        <h3 id="itw">
          <em>In The Wake</em> with The Comrades at Greenhouse Theater Center
        </h3>
        <p>
          written by Lisa Kron
          <br />
          Lighting design by Nicholas Coso, scenic design by Rachel Rauscher, projections design by G. Max Maxin IV,
          costume design by Alycia Matz, sound design by Becca Venable
          <br />
          Photos by Paul Goyette
        </p>
      </div>
      <div className="gallery-wrapper">
        <a
          rel="itw"
          href="/images/ITW_gallery/1.1proj.jpg"
          className="swipebox"
          title="Rose Sengenberger in In The Wake by Lisa Kron"
        >
          <img src="/images/ITW_gallery/1.1proj.jpg" width="300px" alt="Rose Sengenberger" />
        </a>
        <a
          rel="itw"
          href="/images/ITW_gallery/1.1tickle.jpg"
          className="swipebox"
          title="Mike Newquist, Erin O'Brien, Adrienne Matzen and Rose Sengenberger in In The Wake by Lisa Kron"
        >
          <img
            src="/images/ITW_gallery/1.1tickle.jpg"
            width="300px"
            alt="Mike Newquist, Erin O'Brien, Adrienne Matzen and Rose Sengenberger"
          />
        </a>
        <a
          rel="itw"
          href="/images/ITW_gallery/1.1escape.jpg"
          className="swipebox"
          title="Kelli Walker and Rose Sengenberger in In The Wake by Lisa Kron"
        >
          <img src="/images/ITW_gallery/1.1escape.jpg" width="300px" alt="Kelli Walker and Rose Sengenberger" />
        </a>
        <a
          rel="itw"
          href="/images/ITW_gallery/1.2kiss.jpg"
          className="swipebox"
          title="Alison Plott and Rose Sengenberger in In The Wake by Lisa Kron"
        >
          <img src="/images/ITW_gallery/1.2kiss.jpg" width="300px" alt="Alison Plott and Rose Sengenberger" />
        </a>
        <a
          rel="itw"
          href="/images/ITW_gallery/1.4kayla.jpg"
          className="swipebox"
          title="Adrienne Matzen and Kelli Walker in In The Wake by Lisa Kron"
        >
          <img src="/images/ITW_gallery/1.4kayla.jpg" width="300px" alt="Adrienne Matzen and Kelli Walker" />
        </a>
        <a
          rel="itw"
          href="/images/ITW_gallery/1.4tessa.jpg"
          className="swipebox"
          title="Samantha Newcomb and Adrienne Matzen in In The Wake by Lisa Kron"
        >
          <img src="/images/ITW_gallery/1.4tessa.jpg" width="300px" alt="Samantha Newcomb and Adrienne Matzen" />
        </a>
        <a
          rel="itw"
          href="/images/ITW_gallery/2.1love.jpg"
          className="swipebox"
          title="Alison Plott and Rose Sengenberger in In The Wake by Lisa Kron"
        >
          <img src="/images/ITW_gallery/2.1love.jpg" width="300px" alt="Alison Plott and Rose Sengenberger" />
        </a>
        <a
          rel="itw"
          href="/images/ITW_gallery/2.2feet.jpg"
          className="swipebox"
          title="Erin O'Brien and Adrienne Matzen in In The Wake by Lisa Kron"
        >
          <img src="/images/ITW_gallery/2.2feet.jpg" width="300px" alt="Erin O'Brien and Adrienne Matzen" />
        </a>

        <a
          rel="itw"
          href="/images/ITW_gallery/2.7nothing.jpg"
          className="swipebox"
          title="Rose Sengenberger in In The Wake by Lisa Kron"
        >
          <img src="/images/ITW_gallery/2.7nothing.jpg" width="300px" alt="Rose Sengenberger" />
        </a>
      </div>

      <hr className="post" />

      {/*I Run With You*/}
      <div className="gallery-header">
        <h3 id="ak">
          <em>I Run With You</em> at the Crisis Art Festival in Arezzo, Italy
        </h3>
        <p>
          written and performed by Anna/Kate
          <br />
          A theatrical song cycle about queer love, grief, and depression.
          <br />
          Listen to Anna/Kate's album EP{' '}
          <a href="https://annakatemusic.bandcamp.com/album/i-run-with-you-e-p" target="_blank" rel="noreferrer">
            here
          </a>
        </p>
      </div>
      <div className="gallery-wrapper">
        <a
          rel="ak"
          href="/images/annakate_gallery/irunwithyou1.JPG"
          className="swipebox"
          title="Set for I Run With You at the Crisis Art Festival in Arezzo, Italy"
        >
          <img src="/images/annakate_gallery/irunwithyou1.JPG" width="300px" alt="I Run With You" />
        </a>
        <a
          rel="ak"
          href="/images/annakate_gallery/irunwithyou4.JPG"
          className="swipebox"
          title="Anna Gothard and Kate Foster in I Run With You at the Crisis Art Festival in Arezzo, Italy"
        >
          <img src="/images/annakate_gallery/irunwithyou4.JPG" width="300px" alt="Anna/Kate" />
        </a>
        <a
          rel="ak"
          href="/images/annakate_gallery/irunwithyou3.JPG"
          className="swipebox"
          title="Anna Gothard and Kate Foster in I Run With You at the Crisis Art Festival in Arezzo, Italy"
        >
          <img src="/images/annakate_gallery/irunwithyou3.JPG" width="300px" alt="Anna/Kate" />
        </a>
        <a
          rel="ak"
          href="/images/annakate_gallery/irunwithyou5.JPG"
          className="swipebox"
          title="Kate Foster and Anna Gothard in I Run With You at the Crisis Art Festival in Arezzo, Italy"
        >
          <img src="/images/annakate_gallery/irunwithyou5-horiz.JPG" width="300px" alt="Anna/Kate" />
        </a>
        <a
          rel="ak"
          href="/images/annakate_gallery/irunwithyou6.JPG"
          className="swipebox"
          title="Kate Foster and Anna Gothard in I Run With You at the Crisis Art Festival in Arezzo, Italy"
        >
          <img src="/images/annakate_gallery/irunwithyou6.JPG" width="300px" alt="Anna/Kate" />
        </a>
        <a
          rel="ak"
          href="/images/annakate_gallery/irunwithyou2.JPG"
          className="swipebox"
          title="Audience for I Run With You at the Crisis Art Festival in Arezzo, Italy"
        >
          <img src="/images/annakate_gallery/irunwithyou2-horiz.JPG" width="300px" alt="Audience for I Run With You" />
        </a>
      </div>

      <hr className="post" />

      {/*DWF*/}
      <div className="gallery-header">
        <h3 id="dwf">
          <em>Dijla Wal Furat: Between the Tigris and the Euphrates</em> with Poetic Theater Productions
        </h3>
        <p>
          written by Maurice Decaul
          <br />
          Lighting Design by Derek Miller, Costume Design by Liene Dobraja, Sound Design by Jamie A. Diaz, Properties
          Design by Travis Bell, Set Design by Paul DePoo III, Technical Direction by Ori Bensimhon, Assistant Direction
          by Jessica Barnhill
          <br />
          Photos by Bjorn Bolinder
        </p>
      </div>
      <div className="gallery-wrapper">
        <a
          rel="dwf"
          href="/images/DWF_gallery/Web_Y1A0085.jpg"
          className="swipebox"
          title="Fahim Hamid and Ankur Rathee in Dijla Wal Furat by Maurice Decaul"
        >
          <img src="/images/DWF_gallery/Web_Y1A0085.jpg" width="300px" alt="Fahim Hamid and Ankur Rathee" />
        </a>
        <a
          rel="dwf"
          href="/images/DWF_gallery/Web_Y1A0100.jpg"
          className="swipebox"
          title="Fahim Hamid and Ankur Rathee in Dijla Wal Furat by Maurice Decaul"
        >
          <img src="/images/DWF_gallery/Web_Y1A0100-horiz.jpg" width="300px" alt="Fahim Hamid and Ankur Rathee" />
        </a>
        <a
          rel="dwf"
          href="/images/DWF_gallery/Web_Y1A0112.jpg"
          className="swipebox"
          title="Perri Yaniv in Dijla Wal Furat by Maurice Decaul"
        >
          <img src="/images/DWF_gallery/Web_Y1A0112-horiz.jpg" width="300px" alt="Perri Yaniv" />
        </a>
        <a
          rel="dwf"
          href="/images/DWF_gallery/Web_Y1A0232.jpg"
          className="swipebox"
          title="Nabil Viñas in Dijla Wal Furat by Maurice Decaul"
        >
          <img src="/images/DWF_gallery/Web_Y1A0232.jpg" width="300px" alt="Nabil Viñas" />
        </a>
        <a
          rel="dwf"
          href="/images/DWF_gallery/Web_Y1A0237.jpg"
          className="swipebox"
          title="Katie Zaffrann in Dijla Wal Furat by Maurice Decaul"
        >
          <img src="/images/DWF_gallery/Web_Y1A0237.jpg" width="300px" alt="Katie Zaffrann" />
        </a>
        <a
          rel="dwf"
          href="/images/DWF_gallery/Web_Y1A0368.jpg"
          className="swipebox"
          title="Nabil Viñas and Temesgen Tocruray in Dijla Wal Furat by Maurice Decaul"
        >
          <img src="/images/DWF_gallery/Web_Y1A0368.jpg" width="300px" alt="Nabil Viñas and Temesgen Tocruray" />
        </a>
        <a
          rel="dwf"
          href="/images/DWF_gallery/Web_Y1A0406.jpg"
          className="swipebox"
          title="Ali Andre Ali in Dijla Wal Furat by Maurice Decaul"
        >
          <img src="/images/DWF_gallery/Web_Y1A0406.jpg" width="300px" alt="Ali Andre Ali" />
        </a>
        <a
          rel="dwf"
          href="/images/DWF_gallery/Web_Y1A0446.jpg"
          className="swipebox"
          title="Temesgen Tocruray and Victory Chappotin in Dijla Wal Furat by Maurice Decaul"
        >
          <img src="/images/DWF_gallery/Web_Y1A0446.jpg" width="300px" alt="Temesgen Tocruray and Victory Chappotin" />
        </a>
        <a
          rel="dwf"
          href="/images/DWF_gallery/Web_Y1A0479.jpg"
          className="swipebox"
          title="Nabil Viñas and Victory Chappotin in Dijla Wal Furat by Maurice Decaul"
        >
          <img src="/images/DWF_gallery/Web_Y1A0479-horiz.jpg" width="300px" alt="Nabil Viñas and Victory Chappotin" />
        </a>
        <a
          rel="dwf"
          href="/images/DWF_gallery/Web_Y1A0546.jpg"
          className="swipebox"
          title="Temesgen Tocruray and Perri Yaniv in Dijla Wal Furat by Maurice Decaul"
        >
          <img src="/images/DWF_gallery/Web_Y1A0546.jpg" width="300px" alt="Temesgen Tocruray and Perri Yaniv" />
        </a>
        <a
          rel="dwf"
          href="/images/DWF_gallery/Web_Y1A0573.jpg"
          className="swipebox"
          title="Katie Zaffrann in Dijla Wal Furat by Maurice Decaul"
        >
          <img src="/images/DWF_gallery/Web_Y1A0573.jpg" width="300px" alt="Katie Zaffrann" />
        </a>
        <a
          rel="dwf"
          href="/images/DWF_gallery/Web_Y1A0602.jpg"
          className="swipebox"
          title="Fahim Hamid and Ankur Rathee in Dijla Wal Furat by Maurice Decaul"
        >
          <img src="/images/DWF_gallery/Web_Y1A0602.jpg" width="300px" alt="Fahim Hamid and Ankur Rathee" />
        </a>
        <a
          rel="dwf"
          href="/images/DWF_gallery/Web_Y1A0673.jpg"
          className="swipebox"
          title="Victory Chappotin in Dijla Wal Furat by Maurice Decaul"
        >
          <img src="/images/DWF_gallery/Web_Y1A0673.jpg" width="300px" alt="Victory Chappotin" />
        </a>
        <a
          rel="dwf"
          href="/images/DWF_gallery/Web_Y1A0026.jpg"
          className="swipebox"
          title="Ali Andre Ali in Dijla Wal Furat by Maurice Decaul"
        >
          <img src="/images/DWF_gallery/Web_Y1A0026.jpg" width="300px" alt="Ali Andre Ali" />
        </a>
      </div>

      <hr className="post" />
      {/*faith*/}
      <div className="gallery-header">
        <h3 id="faith">
          <em>faith</em> at Culture Project's Women Center Stage Festival
        </h3>
        <p>
          written and performed by Caroline Rothstein
          <br />
          A one-woman play about surviving a decade-long eating disorder in poetry and prose. Developed with Poetic
          Theater Productions and remounted at Planet Connections Theatre Festivity and Poetic License in partnership
          with Timberline Knolls. Recipient of the 2012 Planet Connections Theatre Festivity Award for Outstanding
          Production of a Solo Show.
          <br />
        </p>
      </div>
      <div className="gallery-wrapper">
        <a rel="faith" href="/images/faith/Faith_015.jpg" className="swipebox" title="Caroline Rothstein in faith">
          <img src="/images/faith/Faith_015.jpg" width="200px" alt="Caroline Rothstein in faith" />
        </a>

        <a rel="faith" href="/images/faith/Faith_085.jpg" className="swipebox" title="Caroline Rothstein in faith">
          <img src="/images/faith/Faith_085.jpg" width="200px" alt="Caroline Rothstein in faith" />
        </a>
        <a rel="faith" href="/images/faith/Faith_031.jpg" className="swipebox" title="Caroline Rothstein in faith">
          <img src="/images/faith/Faith_031.jpg" width="200px" alt="Caroline Rothstein in faith" />
        </a>
        <a rel="faith" href="/images/faith/Faith_103.jpg" className="swipebox" title="Caroline Rothstein in faith">
          <img src="/images/faith/Faith_103.jpg" width="200px" alt="Caroline Rothstein in faith" />
        </a>
        <a rel="faith" href="/images/faith/Faith_130.jpg" className="swipebox" title="Caroline Rothstein in faith">
          <img src="/images/faith/Faith_130.jpg" width="200px" alt="Caroline Rothstein in faith" />
        </a>
      </div>

      <hr className="post" />

      {/*Writopia*/}
      <div className="gallery-header">
        <h3 id="writopia">Writopia Worldwide Plays Festival</h3>
        <p>
          Click the links for video
          <br />
          <a href="https://www.youtube.com/watch?v=c2VWBbBUV9s" target="_blank" rel="noreferrer">
            <em>Painting a Wife in Watercolor</em> by Lizzie McCord
          </a>
          <br />
          <a href="https://www.youtube.com/watch?v=XBAk-O4KNWQ&t=179s" target="_blank" rel="noreferrer">
            <em>Numb</em> by Eleanor Mammen
          </a>
          <br />
          <a href="https://www.youtube.com/watch?v=pYGemznjBQ8" target="_blank" rel="noreferrer">
            <em>March 21st</em> by Kai Williams
          </a>
          <br />
          <a href="https://www.youtube.com/watch?v=TaVkq_ZEuS0" target="_blank" rel="noreferrer">
            <em>Tales of the Fitzwilliams</em> by Davis, Davis, Romeo and Romeo
          </a>
          <br />
          <a href="https://www.youtube.com/watch?v=K5wlr75TDes" target="_blank" rel="noreferrer">
            <em>The Epic Of</em> by Rachel Calnek-Sugin
          </a>
          <br />
        </p>
      </div>
      <div className="gallery-wrapper">
        <a
          rel="writopia"
          href="/images/writopia/watercolor.jpg"
          className="swipebox"
          title="Painting a Wife in Watercolor"
        >
          <img src="/images/writopia/watercolor.jpg" width="300px" alt="Painting a Wife in Watercolor" />
        </a>

        <a
          rel="writopia"
          href="/images/writopia/numb2.jpg"
          className="swipebox"
          title="Maggie Lalley and Edward Chin-Lyn in Numb"
        >
          <img src="/images/writopia/numb2.jpg" width="300px" alt="Numb" />
        </a>
        <a
          rel="writopia"
          href="/images/writopia/march1.jpg"
          className="swipebox"
          title="Simoné Elizabeth Bart, Roland Lane and Alesandra Nahodil in March 21st"
        >
          <img src="/images/writopia/march1.jpg" width="300px" alt="March 21st" />
        </a>

        <a
          rel="writopia"
          href="/images/writopia/fitz2.jpg"
          className="swipebox"
          title="Mike Wirsch in Tales of the Fitzwilliams"
        >
          <img src="/images/writopia/fitz2.jpg" width="300px" alt="Tales of the Fitzwilliams" />
        </a>
        <a
          rel="writopia"
          href="/images/writopia/fitz3.jpg"
          className="swipebox"
          title="Jarrod Bates in Tales of the Fitzwilliams"
        >
          <img src="/images/writopia/fitz3-horiz.jpg" width="300px" alt="Tales of the Fitzwilliams" />
        </a>

        <a
          rel="writopia"
          href="/images/writopia/epic1.jpg"
          className="swipebox"
          title="Samantha Cooper and Temesgen Tocruray in The Epic Of by Rachel Calnek-Sugin"
        >
          <img src="/images/writopia/epic1.jpg" width="300px" alt="The Epic Of" />
        </a>
      </div>

      {/*<div className="gallery-header">
<a name="refuge"></a>
<h3><em>The Refuge Project</em> with Poetic Theater Productions</h3>
Devised by the ensemble<br />

</p>
</div>
<div className="gallery-wrapper">
  <a rel = "refuge" href="/images/refuge/candle.jpg" className="swipebox" title="The Refuge Project">
  <img src="/images/refuge/candle.jpg" width="300px" alt="#5 Candle">
  </a>


</div>*/}

      <hr className="post" />

      {/*Goliath*/}
      <div className="gallery-header">
        <h3 id="goliath">
          <em>Goliath</em> with Poetic Theater Productions at Wild Project
        </h3>
        <p>
          written by Takeo Rivera
          <br />
          featuring Samantha Cooper, Dontonio Demarco, Natalia Duong, Edgar Eguia, Kenneth Heaton, Monique Paige and
          Nabil Viñas
          <br />
          lighting design by Brad Peterson, stage managed by Sarah Livant
          <br />
          Photos by Hunter Canning and Kate Hess
        </p>
      </div>
      <div className="gallery-wrapper">
        <a
          rel="goliath"
          href="/images/goliath_gallery/neverseeyouagain.jpg"
          className="swipebox"
          title="Nabil Viñas and ensemble in Goliath by Takeo Rivera"
        >
          <img src="/images/goliath_gallery/neverseeyouagain.jpg" width="300px" alt="Nabil Viñas and ensemble" />
        </a>
        <a
          rel="goliath"
          href="/images/goliath_gallery/vietnam.jpg"
          className="swipebox"
          title="Kenneth Heaton and ensemble in Goliath by Takeo Rivera"
        >
          <img src="/images/goliath_gallery/vietnam.jpg" width="300px" alt="Kenneth Heaton and ensemble" />
        </a>
        <a
          rel="goliath"
          href="/images/goliath_gallery/howmanyother.jpg"
          className="swipebox"
          title="Ensemble in Goliath by Takeo Rivera"
        >
          <img src="/images/goliath_gallery/howmanyother.jpg" width="300px" alt="Ensemble" />
        </a>
        <a
          rel="goliath"
          href="/images/goliath_gallery/hesmybrother.jpg"
          className="swipebox"
          title="Samantha Cooper in Goliath by Takeo Rivera"
        >
          <img src="/images/goliath_gallery/hesmybrother.jpg" width="300px" alt="Samantha Cooper" />
        </a>
        <a
          rel="goliath"
          href="/images/goliath_gallery/waywayback.jpg"
          className="swipebox"
          title="Nabil Viñas and Edgar Eguia in Goliath by Takeo Rivera"
        >
          <img src="/images/goliath_gallery/waywayback.jpg" width="300px" alt="Nabil Viñas and Edgar Eguia" />
        </a>

        <a
          rel="goliath"
          href="/images/goliath_gallery/yellowribbons.jpg"
          className="swipebox"
          title="Monique Paige in Goliath by Takeo Rivera"
        >
          <img src="/images/goliath_gallery/yellowribbons.jpg" width="300px" alt="Monique Paige" />
        </a>

        <a
          rel="goliath"
          href="/images/goliath_gallery/racistassholes.jpg"
          className="swipebox"
          title="Ensemble in Goliath by Takeo Rivera"
        >
          <img src="/images/goliath_gallery/racistassholes-horiz.jpg" width="300px" alt="Ensemble" />
        </a>

        <a
          rel="goliath"
          href="/images/goliath_gallery/ANSWER.jpg"
          className="swipebox"
          title="Samantha Cooper in Goliath by Takeo Rivera"
        >
          <img src="/images/goliath_gallery/ANSWER.jpg" width="300px" alt="Samantha Cooper" />
        </a>

        <a
          rel="goliath"
          href="/images/goliath_gallery/ladies.jpg"
          className="swipebox"
          title="Dontonio Demarco and Nabil Viñas in Goliath by Takeo Rivera"
        >
          <img src="/images/goliath_gallery/ladies.jpg" width="300px" alt="Dontonio Demarco and Nabil Viñas" />
        </a>
        <a
          rel="goliath"
          href="/images/goliath_gallery/unclesam.jpg"
          className="swipebox"
          title="Nabil Viñas in Goliath by Takeo Rivera"
        >
          <img src="/images/goliath_gallery/unclesam-horiz.jpg" width="300px" alt="Nabil Viñas" />
        </a>

        <a
          rel="goliath"
          href="/images/goliath_gallery/marriedhim.jpg"
          className="swipebox"
          title="Natalia Duong and Nabil Viñas in Goliath by Takeo Rivera"
        >
          <img src="/images/goliath_gallery/marriedhim.jpg" width="300px" alt="Natalia Duong and Nabil Viñas" />
        </a>

        <a
          rel="goliath"
          href="/images/goliath_gallery/likealaddin.jpg"
          className="swipebox"
          title="Ensemble in Goliath by Takeo Rivera"
        >
          <img src="/images/goliath_gallery/likealaddin.jpg" width="300px" alt="Ensemble" />
        </a>
        <a
          rel="goliath"
          href="/images/goliath_gallery/seedad.jpg"
          className="swipebox"
          title="Nabil Viñas in Goliath by Takeo Rivera"
        >
          <img src="/images/goliath_gallery/seedad.jpg" width="300px" alt="Nabil Viñas" />
        </a>

        <a
          rel="goliath"
          href="/images/goliath_gallery/welcomemehome.jpg"
          className="swipebox"
          title="Nabil Viñas and Monique Paige in Goliath by Takeo Rivera"
        >
          <img
            src="/images/goliath_gallery/welcomemehome-horiz.jpg"
            width="300px"
            alt="Nabil Viñas and Monique Paige"
          />
        </a>
        <a
          rel="goliath"
          href="/images/goliath_gallery/proudofme.jpg"
          className="swipebox"
          title="Nabil Viñas and Kenneth Heaton in Goliath by Takeo Rivera"
        >
          <img src="/images/goliath_gallery/proudofme.jpg" width="300px" alt="Nabil Viñas and Kenneth Heaton" />
        </a>
      </div>
    </>
  );
};
export default Gallery;
