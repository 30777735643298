import './home.css';

const Home = () => {
  return (
    <>
      <div className="row">
        {/* Carousel */}
        <div id="myCarousel" className="carousel slide" data-ride="carousel">
          {/* Indicators */}
          <ol className="carousel-indicators">
            <li
              data-target="#myCarousel"
              data-slide-to="0"
              className="active"
            ></li>
            <li data-target="#myCarousel" data-slide-to="1"></li>
            <li data-target="#myCarousel" data-slide-to="2"></li>
            <li data-target="#myCarousel" data-slide-to="3"></li>
            <li data-target="#myCarousel" data-slide-to="4"></li>
            <li data-target="#myCarousel" data-slide-to="5"></li>
            <li data-target="#myCarousel" data-slide-to="6"></li>
            <li data-target="#myCarousel" data-slide-to="7"></li>
            <li data-target="#myCarousel" data-slide-to="8"></li>
            <li data-target="#myCarousel" data-slide-to="9"></li>
            <li data-target="#myCarousel" data-slide-to="10"></li>
            <li data-target="#myCarousel" data-slide-to="11"></li>
            <li data-target="#myCarousel" data-slide-to="12"></li>
            <li data-target="#myCarousel" data-slide-to="13"></li>
            <li data-target="#myCarousel" data-slide-to="14"></li>
            <li data-target="#myCarousel" data-slide-to="15"></li>
            <li data-target="#myCarousel" data-slide-to="16"></li>
            <li data-target="#myCarousel" data-slide-to="17"></li>
            <li data-target="#myCarousel" data-slide-to="18"></li>
            <li data-target="#myCarousel" data-slide-to="19"></li>
            <li data-target="#myCarousel" data-slide-to="20"></li>
            <li data-target="#myCarousel" data-slide-to="21"></li>
          </ol>
          <div className="carousel-inner" role="listbox">
            {/* Mary Stuart */}
            <div className="item active">
              <img
                className="slide"
                src="/images/mary/MaryStuart-171-X3.jpg"
                alt="Mary Stuart"
              />{' '}
              {/*confrontation*/}
            </div>
            <div className="item">
              <img
                className="slide"
                src="/images/mary/MaryStuart-323-X3.jpg"
                alt="Mary Stuart"
              />{' '}
              {/*martyr Mary*/}
            </div>

            <div className="item">
              <img
                className="slide"
                src="/images/mary/3L9A2754-X2.jpg"
                alt="Mary Stuart"
              />
              {/*Mortimer*/}
            </div>
            <div className="item">
              <img
                className="slide"
                src="/images/mary/MaryStuart-296-X3.jpg"
                alt="Mary Stuart"
              />
              {/*Melvil*/}
            </div>

            {/*}<div className="item">
              <img
                className="slide"
                src="/images/mary/MaryStuart-65-X3.jpg"
                alt="Mary Stuart"
              />
              {/*shake
            </div>*/}

            {/*}<div className="item">
              <img
                className="slide"
                src="/images/mary/MaryStuart-236-X3.jpg"
                alt="Mary Stuart"
              />
              {/*Burleigh
            </div>*/}
            <div className="item">
              <img
                className="slide"
                src="/images/mary/MaryStuart-119-X3.jpg"
                alt="Mary Stuart"
              />

              {/*mort and leicester*/}
                </div>
              <div className="item">
                <img
                  className="slide"
                  src="/images/mary/3L9A2629-X2.jpg"
                  alt="Mary Stuart"
                />
                {/*confrontation 2*/}
              </div>

            <div className="item">
              <img
                className="slide"
                src="/images/mary/MaryStuart-335-X3.jpg"
                alt="Mary Stuart"
              />
              {/*Talbot begs*/}
            </div>
            <div className="item">
              <img
                className="slide"
                src="/images/mary/MaryStuart-316-X3.jpg"
                alt="Mary Stuart"
              />{' '}
              {/*execution*/}
            </div>


            {/* Spring Awakening */}
            <div className="item">
              <img
                className="slide"
                src="/images/spring/boys.jpg"
                alt="Spring Awakening"
              />
              {/*boys*/}
            </div>
            <div className="item">
              <img
                className="slide"
                src="/images/spring/moritzilse.jpg"
                alt="Spring Awakening"
              />
              {/*ilse*/}
            </div>
            {/* Viterbo */}
            <div className="item">
              <img
                className="slide"
                src="/images/elephant/_APR4099_1200px.jpg"
                alt="Elephant Speaks Jazz"
              />
            </div>
            <div className="item">
              <img
                className="slide"
                src="/images/elephant/_APR4003_1200px.jpg"
                alt="Elephant Speaks Jazz"
              />
            </div>

            <div className="item">
              <img
                className="slide"
                src="/images/pajama/IMG_3533-Overhouse.jpg"
                alt="Pajama Game"
              />
            </div>
            <div className="item">
              <img
                className="slide"
                src="/images/pajama/IMG_3389-Overhouse.jpg"
                alt="Pajama Game"
              />
            </div>
            {/* Anna/Kate */}
            <div className="item">
              <img
                className="slide"
                src="/images/annakate_gallery/irunwithyou4.JPG"
                alt="Anna/Kate"
              />
            </div>
            <div className="item">
              <img
                className="slide"
                src="/images/annakate_gallery/irunwithyou6.JPG"
                alt="Anna/Kate"
              />
            </div>
            {/* Dijla Wal Furat */}

            <div className="item">
              <img
                className="slide"
                src="/images/DWF_gallery/Web_Y1A0368.jpg"
                alt="Dijla Wal Furat"
              />
              {/*Nabil Temesgen*/}
            </div>
            <div className="item">
              <img
                className="slide"
                src="/images/carousel/Web_Y1A0602.jpg"
                alt="Dijla Wal Furat"
              />
              {/*Fahim*/}
            </div>
            {/*<div className="item">
              <img
                className="slide"
                src="/images/carousel/Web_Y1A0573.jpg"
                alt="Dijla Wal Furat"
              />
              {/*Katie
            </div>*/}
            <div className="item">
              <img
                className="slide"
                src="/images/carousel/Web_Y1A0546.jpg"
                alt="Dijla Wal Furat"
              />
              {/*Perri Temesgen*/}
            </div>
            <div className="item">
              <img
                className="slide"
                src="/images/carousel/Web_Y1A0673.jpg"
                alt="Dijla Wal Furat"
              />
              {/*Victory*/}
            </div>

            {/* <div className="item">
        <img className="slide" src="/images/carousel/Savage_Essay06.jpg" alt="Refuge Project">
      </div> */}
            <div className="item">
              <img
                className="slide"
                src="/images/carousel/Telling_Victoria.jpg"
                alt="Telling"
              />
            </div>

            {/* <div className="item">
              <img
                className="slide"
                src="/images/carousel/goliath_031_crop.jpeg"
                alt="Goliath"
              />
            </div>
            <div className="item">
              <img
                className="slide"
                src="/images/carousel/goliath_053.jpg"
                alt="Goliath"
              />
            </div>
            <div className="item">
              <img
                className="slide"
                src="/images/carousel/Mike.jpg"
                alt="Writopia"
              />
            </div>
            <div className="item">
              <img
                className="slide"
                src="/images/carousel/DSC_3061.jpg"
                alt="Ravel"
              />
            </div> */}
          </div>
          <a
            className="left carousel-control"
            href="#myCarousel"
            role="button"
            data-slide="prev"
          >
            <span
              className="glyphicon glyphicon-chevron-left"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Previous</span>
          </a>
          <a
            className="right carousel-control"
            href="#myCarousel"
            role="button"
            data-slide="next"
          >
            <span
              className="glyphicon glyphicon-chevron-right"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
        {/* /.carousel */}
      </div>
      <div className="col-sm-16" id="homecontent">
        <div className="col-xs-12">
          <span className="productions">
            <br />
            <div className="col-xs-12">

              <span className="playsnoemp">
                <strong>Upcoming Projects for 2024-2025</strong>
              </span>
              </div>
<div className="col-sm-4">
  <p><strong><em>The Cave</em></strong> by Sadieh Rifai <br></br>
  A Red Orchid Theatre in Chicago<br></br>
  January 30 - March 16, 2025
</p>
</div>
<div className="col-sm-4">
<p>
<strong><em>My Name is Rachel Corrie</em></strong> <br></br>
taken from the writings of Rachel Corrie <br></br>
edited by Katherine Viner and Alan Rickman
</p>
</div>

<div className="col-sm-4">
<p> <strong><em>Lysistrata</em></strong> by Ellen McLaughlin <br></br>
The Theatre School at DePaul University <br></br>
October 30 - November 3, 2024 </p>
</div>
<div className="col-xs-12">
                <span className="playsnoemp">
                  <strong>In Development</strong>
                </span>
                <ul>
                <li> <strong><em>A Fatal Place</em></strong> &mdash; a modern adaptation of Eurpides' <em>Iphigenia at Aulis</em> by Hallie Palladino </li>
                <li> <strong><em>Six Million Jews Didn't Die For You To... </em></strong> &mdash; a one-woman show written and performed by Jana Krumholtz </li>
                <li> <strong><em>Soul.Net</em></strong> &mdash; an interactive community experience created in collaboration with performance maker Kost</li>
</ul>

              <p>
                <span className="playsnoemp">
                  <strong>About The Metal Shop Performance Lab</strong>
                </span>
                <br />
                Alex created The Metal Shop in August 2019 with the mission to
                create theatrical events that foster radical community-building
                and authentic exchange between artists and audiences. Please
                visit{' '}
                <a href="https://www.themetalshop.org" target="_blank" rel="noreferrer">
                  <strong>https://www.themetalshop.org</strong>
                </a>{' '}
                to learn more about our current projects or to get involved.
              </p>

              <span className="playsnoemp">
                          <strong>Further News</strong>
                          </span>
                          <p>
                          Alex was featured on Episode 22 of the 101 Stage Adaptations Podcast talking about her original translation of <em>Spring Awakening</em>.
                          Listen to the episode <a href = "https://101stageadaptations.buzzsprout.com/1999313/12331602-10-spring-awakening-by-alex-mallory-ep-22" target = "_blank" rel="noreferrer"> here</a> or wherever you get your podcasts!
                          </p>
            </div>
          </span>
        </div>
      </div>
    </>
  );
};
export default Home;
