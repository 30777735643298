const Media = () => {
  return (
    <div>
      <div>
        <h2>Articles</h2>
        <div className="post">
          <p>
            <a
              href="https://dailynorthwestern.com/2018/05/25/lateststories/mfa-student-adapts-german-play-about-adolescence-generational-conflict/"
              target="_blank" rel="noreferrer"
            >
              "MFA student adapts German play about adolescence, generational
              conflict"
            </a>
            <br />
            Daily Northwestern feature on "Spring Awakening".
          </p>
          <p>
            <a
              href="https://www.stanforddaily.com/2016/02/17/telling-stanford-to-share-stories-from-stanfords-military-affiliated-community/"
              target="_blank" rel="noreferrer"
            >
              "‘Telling: Stanford’ to share stories from Stanford’s
              military-affiliated community"
            </a>
            <br />
            The Stanford Daily feature on "Telling: Stanford".
          </p>
        </div>
        <h2>Interviews</h2>
        <div className="post">
          <p>
            <a href="http://vappodcast.com/alexmallory" target="_blank">
              VAP Podcast: Civic Empathy Through Theater
            </a>
            <br />I discuss vets, theater, civic empathy, directing and more
            with VAP Founder and President BR McDonald.
          </p>
          <p>
            <a
              href="http://goseeashowpodcast.com/2015/02/20/maurice-decaul-alex-mallory-and-the-cast-of-dijla-wal-furat-between-the-tigris-and-the-euphrates/"
              target="_blank" rel="noreferrer"
            >
              Go See A Show: Dijla Wal Furat
            </a>
            <br />
            Maurice Decaul, Alex Mallory, and the cast of “Dijla Wal Furat:
            Between the Tigris and the Euphrates”
          </p>
          <p>
            <a
              href="http://regardingarts.com/theater/goliath_adv.html"
              target="_blank" rel="noreferrer"
            >
              Regarding Arts
            </a>
            <br />
            Bringing it All Back Home: Playwright, director talk about returning
            'Goliath' to where it was born: Stanford University
          </p>
          <p>
            <a
              href="http://www.theaterspeak.org/2012/04/what-is-faith-about-caroline-rothstein.html"
              target="_blank" rel="noreferrer"
            >
              Theaterspeak
            </a>
            <br />
            Caroline Rothstein's New Play "faith" Directed by Alex Mallory at
            Women's Center Stage
          </p>
          <p>
            <a
              href="http://goseeashowpodcast.com/2012/01/05/alex-mallory-jeffrey-karafin-artistic-directors-of-poetic-theater-productions-and-the-festival-poetic-license/"
              target="_blank" rel="noreferrer"
            >
              Go See A Show: Poetic License
            </a>
            <br />
            Alex Mallory &amp; Jeremy Karafin, Artistic Directors of Poetic
            Theater Productions and the festival “Poetic License”
          </p>
          <p>
            <a
              href="http://zackcalhoon.blogspot.com/2011/04/people-you-should-know-alex-mallory.html"
              target="_blank" rel="noreferrer"
            >
              People You Should Know
            </a>
            <br />
            People You Should Know: Alex Mallory
          </p>
          <p>
            <a
              href="https://worksbywomen.wordpress.com/2011/03/04/interview-alex-mallor/"
              target="_blank" rel="noreferrer"
            >
              Works by Women
            </a>
            <br />
            Women Center Stage 2011: Interview with Alex Mallory
          </p>
        </div>
      </div>
      <br />
      <hr className="post" />
    </div>
  );
};
export default Media;
